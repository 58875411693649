import React, { createContext, useContext, useState } from 'react';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
    const baseURL = process.env.REACT_APP_WEBSOCKET_ADDRESS;
    const [sourceUrl, setSourceUrl] = useState(baseURL);

    const resetUrl = () => {
        setSourceUrl(baseURL)
    }

    return (
        <WebSocketContext.Provider value={{ sourceUrl, setSourceUrl, resetUrl }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export const useWebSocketContext = () => {
    return useContext(WebSocketContext);
};
