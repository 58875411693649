import React from "react";
import moment from "moment";
import { Card } from "react-bootstrap";
import { useQuery, useMutation } from "react-query";
import { deleteUser, getUsers } from "../../services";
import { Table } from "../../components/Tables";
import { useProfile } from "../../contexts";
import { UsersModal } from "./components";
import { toast, Slide } from "react-toastify";
import { DeleteModal } from "./components/deleteModal";

const Users = () => {
  const [data, setData] = React.useState();
  const [limit] = React.useState(100);
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [page] = React.useState(1);
  const { organizationId, profileData } = useProfile();

  const { isLoading, isRefetching, refetch } = useQuery(
    [
      "users-request",
      {
        limit,
        offset: (page - 1) * limit,
      },
    ],
    getUsers,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: data => {
        setData(data);
      },
    }
  );

  const { mutate: deleteAccount } = useMutation(deleteUser, {
    onSuccess: () => {
      setShowDeleteModal(false);
      toastAlert("success");
      refetch();
      selectedData && setSelectedData(null);
    },
    onError: () => {
      toastAlert("error");
      setShowDeleteModal(false);
      selectedData && setSelectedData(null);
    },
  });

  const toastAlert = type =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `User deleted succesfuly`
          : `Couldn't delete user`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-info" : "bg-danger",
        type,
      }
    );

  const columns = [
    {
      className: "text-center",
      key: "all",
      name: "Full Name",
      render: data => (
        <span
          className="d-block text-truncate mx-auto"
          style={{ maxWidth: 140 }}
        >
          {data.first_name + " " + data.last_name}
        </span>
      ),
    },
    { className: "text-center", key: "email", name: "Email" },
    {
      className: "text-center",
      key: "roles",
      name: "Roles",
      render: data => {
        return (
          <>
            {data?.map((role, key) => (
              <span key={key} className="badge bg-primary me-1 my-2 tx-12">
                {role?.name}
              </span>
            ))}
          </>
        );
      },
    },
    {
      className: "text-center",
      key: "created_at",
      width: 120,
      name: "Created at",
      render: data => (
        <>
          {!data ? (
            "-"
          ) : (
            <>
              <span className="d-block">
                {moment(data).format("DD-MM-YYYY")}
              </span>
              <span className="d-block">
                {moment(data).format("HH:mm UTC")}
              </span>
            </>
          )}
        </>
      ),
    },
    {
      className: "text-center",
      key: "all",
      name: "Actions",
      width: 60,
      render: data =>
        data?.roles?.[0]?.type === "admin" ||
        data?.roles?.[0]?.type === "root" ? null : (
          <div className="d-flex align-items-center justify-content-center">
            <button
              className={`btn ${
                data?.id === profileData?.id
                  ? "btn-dark-light"
                  : "btn-outline-danger"
              } d-flex align-items-center justify-content-center p-1`}
              disabled={data?.id === profileData?.id}
              onClick={() => {
                if (data?.id === profileData?.id) {
                  return;
                }
                setSelectedData(data);
                setShowDeleteModal(true);
              }}
            >
              <i className="fe fe-trash" />
            </button>
          </div>
        ),
    },
  ];

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [organizationId]);

  return (
    <>
      {showModal && (
        <UsersModal setShowModal={setShowModal} onSettled={refetch} />
      )}
      {showDeleteModal && (
        <DeleteModal
          title="User"
          onAccept={() => deleteAccount(selectedData?.id)}
          setShowModal={() => {
            setShowDeleteModal();
            setSelectedData(null);
          }}
          additionalInfo={`Fullname: ${selectedData?.first_name} ${selectedData?.last_name}`}
        />
      )}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Users</span>
        </div>
        <div className="right-content">
          <button
            className="btn btn-info t-16 py-2 d-flex align-items-center justify-content-center"
            onClick={() => setShowModal(true)}
          >
            <i className="fe fe-plus"></i>
          </button>
        </div>
      </div>
      <Card>
        <Card.Body className="pt-4 example1-table">
          <Table
            loading={isLoading || isRefetching}
            data={data ? data : []}
            columns={columns}
            total={data ? data?.length : 0}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Users;
