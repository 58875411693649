import React from "react";
import moment from "moment";
import ReactDOM from "react-dom";
import {
  CurrencyContextProvider,
  ProfileContextProvider,
  TwoAuthContextProvider,
} from "./contexts";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Auth from "./pages/Authentication/auth";
import "./index.scss";
import Loader from "./components/Loader/Loader";
import Users from "./pages/Users-Roles/Users";
import Dashboard from "./pages/Dashboard";
import Roles from "./pages/Users-Roles/Roles";
import UsersReport from './pages/GameData/Users/UsersReport';
import Sessions from "./pages/GameData/Sessions";
import SessionDetail from "./pages/SessionDetail";
import Gameplays from "./pages/GameData/Gameplays";
import GameplayDetail from "./pages/SpinDetail";
import Permissions from "./pages/Permissons";
import Settings from "./pages/Settings";
import AuthLogin from "./pages/Authentication/Login";
import CurrenciesMultipliers from "./pages/Currencies/CurrenciesMultipliers/CurrenciesMultipliers";
import WagerSets from "./pages/GamesManagement/WagerSets/WagerSets";
import CurrenciesSets from "./pages/Currencies/CurrenciesSets/CurrenciesSets";
import GamesInfo from "./pages/GamesManagement/GamesInfo/GamesInfo";
import GameDetail from "./pages/GamesManagement/GameDetail/GameDetail";
import UserDetails from "./pages/UserDetails/UserDetails";
import ByGame from "./pages/FinancialReports/ByGame/ByGame";
import ByCountry from "./pages/FinancialReports/ByCountry/ByCountry";
import Downloads from "./pages/Downloads/Downloads";
import {NotificationProvider} from "./contexts/notificationContext";
import {WebSocketProvider} from "./contexts/websocketContext";
import {DemoContextProvider} from "./contexts/demoContext";
import {DownloadProvider} from "./contexts/downloadContext";
const App = React.lazy(() => import("../src/App"));

const AppComponent = () => {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { retry: 0 } },
  });
  const loggedIn = window
    ? window.localStorage.getItem("access_token") &&
      moment(window.localStorage.getItem("expired_at")).diff(moment()) > 0
    : false;

  return (
    <QueryClientProvider client={queryClient}>
      <DemoContextProvider>
      <CurrencyContextProvider>
        <ProfileContextProvider>
          <TwoAuthContextProvider>
            <WebSocketProvider>
            <DownloadProvider>
            <NotificationProvider>
            <BrowserRouter>
              <React.Suspense fallback={<Loader />}>
                <Routes>
                  {loggedIn ? (
                    <Route path={`/`} element={<App />}>
                      <Route path={`/`} element={<Dashboard />} />
                      <Route path="/reports">
                        <Route
                            path={`/reports/by-country`}
                            element={<ByCountry />}
                        />
                        <Route
                          path={`/reports/by-game`}
                          element={<ByGame/>} />
                        <Route
                          path={`/reports/sessions`}
                          element={<Sessions />}
                        />
                        <Route
                          path={`/reports/sessions/:id`}
                          element={<SessionDetail />}
                        />
                        <Route
                          path={`/reports/gameplays`}
                          element={<Gameplays />}
                        />
                        <Route
                          path={`/reports/users`}
                          element={<UsersReport/>}
                          />
                        <Route
                          path={`/reports/downloads`}
                          element={<Downloads/>}
                          />
                        <Route
                          path={`/reports/gameplays/:id`}
                          element={<GameplayDetail />}
                        />
                        <Route
                            path={`/reports/users/:id`}
                            element={<UserDetails />}
                        />
                      </Route>
                      <Route path={'/games/info'} element={<GamesInfo/>} />
                      <Route
                          path={`/games/info/:id`}
                          element={<GameDetail />}
                      />
                      <Route path={'/wager-sets'} element={<WagerSets/>} />
                      <Route path={`/currencies/multipliers`} element={<CurrenciesMultipliers />} />
                      <Route path={'/currencies/sets'} element={<CurrenciesSets/>}/>
                      <Route path={`/settings`} element={<Settings />} />
                      <Route path="/bo-users">
                        <Route path={`/bo-users/`} element={<Users />} />
                        <Route path={`/bo-users/roles`} element={<Roles />} />
                        <Route
                          path={`/bo-users/permissions`}
                          element={<Permissions />}
                        />
                      </Route>
                    </Route>
                  ) : (
                    <Route path={`/`} element={<Auth />}>
                      <Route index element={<AuthLogin />} />
                    </Route>
                  )}
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </React.Suspense>
            </BrowserRouter>
            </NotificationProvider>
            </DownloadProvider>
            </WebSocketProvider>
          </TwoAuthContextProvider>
        </ProfileContextProvider>
      </CurrencyContextProvider>
      </DemoContextProvider>
    </QueryClientProvider>
  );
};
ReactDOM.render(
  <React.StrictMode>
    <AppComponent />
  </React.StrictMode>,
  document.getElementById("root")
);
