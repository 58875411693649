import React from "react";
import moment from "moment";
import {Accordion, Carousel} from "react-bootstrap";
import "./table.scss";
import {convertToCurrency} from "../../../utils";
import {shouldShowBonusSpinCounter, shouldShowBonusTriggered, shouldShowRetriggerFreeSpin} from "./consonants";
import {bonus_spin_counter, imageLinkName, payLines, paylines, stages, stops} from "./helpers";

export const ResultTable = ({isAllowed = false, gameName, data}) => {

    const rows = gameName === "asgardparty" || gameName === "ego-draconis" ? [...Array(9).keys()] : [...Array(3).keys()];

    function processResultTable(stage, currentFigures, currentTopFigures) {
        let tableRows;
        if (gameName === "asgardparty" || gameName === "ego-draconis") {
            const {payouts} = stage;
            const winningFigureIds = [];

            if (payouts && payouts.values) {
                payouts.values.forEach((payout) => {
                    if (payout.figures) {
                        winningFigureIds.push(...payout.figures);
                    }
                });
            }

            const topRowFigures = Array.from({length: 7}, (_, figureIndex) => {
                if (figureIndex >= 2 && figureIndex <= 4) {
                    const offset = figureIndex - 2; // Offset to adjust the rendering position
                    const figure = currentTopFigures[offset];
                    const symbol = figure ? figure.symbol : "";

                    const isSymbolInWinLine = winningFigureIds.includes(figure?.id);
                    const isSpecialFigure = figure?.is_special && figure?.name.includes('m');

                    const brightness = isSymbolInWinLine || isSpecialFigure ? 1 : 0.4;

                    return (
                        <td
                            key={figureIndex}
                            style={{
                                filter: `brightness(${brightness})`,
                                backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                    gameName,
                                    isAllowed
                                )}/${figure?.name}.png)`,
                                backgroundSize: "cover",
                            }}
                        >
                            {!imageLinkName(gameName, isAllowed) && symbol?.toUpperCase()}
                        </td>
                    );
                } else {
                    return <td key={figureIndex}></td>;
                }
            });

            tableRows = rows.slice(1).map((rowIndex) => {
                const rowCells = Array.from({length: 7}, (_, figureIndex) => {
                    const figureSet = currentFigures.find(
                        (figSet) => figSet[0]?.y === rowIndex && figSet[0]?.x === figureIndex
                    );

                    const symbol = figureSet ? figureSet[0].symbol : "";

                    let isSymbolInWinLine = figureSet?.find((fig) => winningFigureIds.includes(fig.id));
                    let isSpecialFigure = false;
                    let highlightedFigureIndex = -1;

                    if (figureSet) {
                        for (let i = 0; i < figureSet.length; i++) {
                            const figure = figureSet[i];
                            if (figure.is_special && figure.name.includes("m")) {
                                isSpecialFigure = true;
                                highlightedFigureIndex = i;
                                break;
                            } else if (winningFigureIds.includes(figure.id)) {
                                isSymbolInWinLine = true
                                highlightedFigureIndex = i;
                                break;
                            }
                        }
                    }

                    if (figureSet) {
                        const figureComponents = figureSet.map((figure, index) => {
                            const isHighlighted = isSpecialFigure && index === highlightedFigureIndex || isSymbolInWinLine && index === highlightedFigureIndex;
                            const brightness = isHighlighted ? 1 : 0.4;
                            if (figure.name === "c") {
                                return (
                                    <div
                                        style={{
                                            filter: `brightness(${brightness})`,
                                            backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                                gameName,
                                                isAllowed
                                            )}/${figure.name}.png)`,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            width: "150px",
                                            height: "150px",
                                            position: "absolute",
                                            marginTop: "-24px",
                                            marginLeft: "2.5%",
                                        }}
                                    ></div>
                                );
                            } else if (figure.name === "r1") {
                                return (
                                    <div
                                        style={{
                                            filter: `brightness(${brightness})`,
                                            backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                                gameName,
                                                isAllowed
                                            )}/${figure.name}.png)`,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            width: "50px",
                                            height: "100px",
                                            position: "absolute",
                                            marginTop: "-24px",
                                        }}
                                    ></div>
                                );
                            } else if (figure.name === "r2") {
                                return (
                                    <div
                                        style={{
                                            filter: `brightness(${brightness})`,
                                            backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                                gameName,
                                                isAllowed
                                            )}/${figure.name}.png)`,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            width: "100px",
                                            height: "200px",
                                            position: "absolute",
                                            marginTop: "-24px",
                                            marginLeft: "1%",
                                        }}
                                    ></div>
                                );
                            } else if (figure.name === "h") {
                                return (
                                    <div
                                        style={{
                                            filter: `brightness(${brightness})`,
                                            backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                                gameName,
                                                isAllowed
                                            )}/${figure.name}.png)`,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            width: "145px",
                                            height: "200px",
                                            position: "absolute",
                                            marginTop: "-25px",
                                            marginLeft: "2.8%",
                                        }}
                                    ></div>
                                );
                            } else if (figure.name === "m2" || figure.name === "m12" || figure.name === "m22") {
                                return (
                                    <div
                                        style={{
                                            filter: `brightness(${brightness})`,
                                            backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                                gameName,
                                                isAllowed
                                            )}/${figure.name}.png)`,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            width: "100px",
                                            height: "100px",
                                            position: "absolute",
                                            marginTop: "-25px",
                                            marginLeft: "1.2%",
                                        }}
                                    ></div>
                                );
                            } else if (figure.name === "m3" || figure.name === "m13" || figure.name === "m23") {
                                return (
                                    <div
                                        style={{
                                            filter: `brightness(${brightness})`,
                                            backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                                gameName,
                                                isAllowed
                                            )}/${figure.name}.png)`,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            width: "150px",
                                            height: "150px",
                                            position: "absolute",
                                            marginTop: "-25px",
                                            marginLeft: "2%",
                                        }}
                                    ></div>
                                );
                            } else if (figure.name === "m4") {
                                return (
                                    <div
                                        style={{
                                            filter: `brightness(${brightness})`,
                                            backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                                gameName,
                                                isAllowed
                                            )}/${figure.name}.png)`,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            width: "205px",
                                            height: "205px",
                                            position: "absolute",
                                            marginTop: "-34px",
                                            marginLeft: "3.5%",
                                        }}
                                    ></div>
                                );
                            } else {
                                return (
                                    <div
                                        style={{
                                            filter: `brightness(${brightness})`,
                                            backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                                gameName,
                                                isAllowed
                                            )}/${figure.name}.png)`,
                                            backgroundSize: "cover",
                                            backgroundRepeat: "no-repeat",
                                            width: "50px",
                                            height: "50px",
                                            position: "absolute",
                                            marginTop: "-25px",
                                            marginLeft: "0.2%"
                                        }}
                                    >
                                        {!imageLinkName(gameName, isAllowed) && symbol?.toUpperCase()}
                                    </div>
                                );
                            }
                        });
                        return <td>{figureComponents}</td>;
                    }

                    return (
                        <td
                            key={figureIndex}
                            style={{
                                backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                    gameName,
                                    isAllowed
                                )}/${figureSet?.[0].name}.png)`,
                                backgroundSize: "cover",
                            }}
                        >
                            {!imageLinkName(gameName, isAllowed) && symbol?.toUpperCase()}
                        </td>
                    );
                });

                let rowClass;

                if (rowIndex === 4) {
                    rowClass = "fifth-row";
                } else if (rowIndex >= 4) {
                    rowClass = "bordered-row";
                } else {
                    rowClass = ''
                }

                return <tr key={rowIndex} className={rowClass}>{rowCells}</tr>;
            });

            tableRows.unshift(<tr key="topRow">{topRowFigures}</tr>);

            const lastRowIndex = tableRows.length - 1;
            if (lastRowIndex >= 0) {
                tableRows[lastRowIndex] = React.cloneElement(tableRows[lastRowIndex], {
                    className: "last-row",
                });
            }

        } else {
            tableRows = rows?.map((row, rowKey) => (
                <tr key={rowKey}>
                    {stage?.reel_window?.map((column, columnKey) => {
                        const symbol = column[row];
                        let isSymbolInWinLine = false

                        if (stage?.bonus_game !== null) {
                            if (symbol === "S") {
                                isSymbolInWinLine = true;
                            }
                        }

                        const brightness = isSymbolInWinLine ? 1 : 0.4;

                        return (
                            <td
                                key={columnKey}
                                style={{
                                    filter: `brightness(${brightness})`,
                                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                        gameName,
                                        isAllowed
                                    )}/${symbol}.png)`,
                                }}
                            >
                                {!imageLinkName(gameName, isAllowed) && (symbol ? String(symbol).toUpperCase() : '')}
                            </td>
                        );
                    })}
                </tr>
            ));
        }
        return tableRows;
    }

    function processBonusTable(stage, currentFigures, currentTopFigures) {
        let bonusTableRows;

        if(gameName === "asgardparty" || gameName === "ego-draconis") {
           return processResultTable(stage,  currentFigures, currentTopFigures)
        }

        if (stage.payouts?.scatter_values?.length > 0) {
            replaceSymbols([...stage.reel_window], stage.payouts.scatter_values);
            bonusTableRows = rows?.map((row, rowKey) => (
                <tr key={rowKey}>
                    {stage?.reel_window?.map((column, columnKey) => {
                        const symbol = column[row];
                        const symbolIndex = columnKey + rowKey * stage?.reel_window.length;
                        let isSymbolInWinLine = false

                        const highlightIndexes = {}

                        stage?.payouts?.scatter_values.forEach(scatter => {
                            if(scatter.indexes?.length > 0) {
                                scatter.indexes.forEach(index => {
                                    highlightIndexes[index] = scatter.symbol;
                                });
                            } else {
                                if (stage?.reels?.scatter_position !== undefined) {
                                    stage.reels.scatter_position.forEach(position => {
                                        const { row_id, reel_id } = position;

                                        if(columnKey === reel_id) {
                                            highlightIndexes[symbolIndex] = scatter.symbol;
                                        }
                                    });
                                }
                            }
                        });

                        if (highlightIndexes[symbolIndex]) {
                            isSymbolInWinLine = true;
                        }

                        const brightness = isSymbolInWinLine ? 1 : 0.4;

                        return (
                            <td
                                key={columnKey}
                                style={{
                                    filter: `brightness(${brightness})`,
                                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                        gameName,
                                        isAllowed
                                    )}/${symbol}.png)`,
                                }}
                            >
                                {!imageLinkName(gameName, isAllowed) && (symbol ? String(symbol).toUpperCase() : '')}
                            </td>
                        );
                    })}
                </tr>
            ));
        } else {
            bonusTableRows = rows?.map((row, rowKey) => (
                <tr key={rowKey}>
                    {stage?.reel_window?.map((column, columnKey) => {
                        const symbol = column[row];
                        const symbolIndex = columnKey + rowKey * stage?.reel_window.length;
                        let isSymbolInWinLine = false

                        const highlightIndexes = {}

                        stage?.payouts?.scatter_values?.forEach(scatter => {
                            if(scatter.indexes?.length > 0) {
                                scatter.indexes.forEach(index => {
                                    highlightIndexes[index] = scatter.symbol;
                                });
                            } else {
                                if (stage?.reels?.scatter_position !== undefined) {
                                    stage.reels.scatter_position.forEach(position => {
                                        const { row_id, reel_id } = position;

                                        if(columnKey === reel_id) {
                                            highlightIndexes[symbolIndex] = scatter.symbol;
                                        }
                                    });
                                }
                            }
                        });

                        if (highlightIndexes[symbolIndex]) {
                            isSymbolInWinLine = true;
                        }

                        const brightness = isSymbolInWinLine ? 1 : 0.4;

                        return (
                            <td
                                key={columnKey}
                                style={{
                                    filter: `brightness(${brightness})`,
                                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                        gameName,
                                        isAllowed
                                    )}/${symbol}.png)`,
                                }}
                            >
                                {!imageLinkName(gameName, isAllowed) && (symbol ? String(symbol).toUpperCase() : '')}

                            </td>
                        );
                    })}
                </tr>
            ));
        }
        return bonusTableRows;
    }

    function replaceSymbols(reelWindow, scatterValues) {
        const indexToSymbolMap = {};

        scatterValues.forEach(scatter => {
            if (scatter.indexes?.length > 0) {
                scatter.indexes.forEach(index => {
                    indexToSymbolMap[index] = scatter.symbol;
                });
            } else {
                reelWindow = reelWindow.map(reel => {
                    if (reel.includes(scatter.symbol)) {
                        return reel.fill(scatter.symbol);
                    } else {
                        return reel;
                    }
                });
            }
        });

        for (let rowIndex = 0; rowIndex < reelWindow.length; rowIndex++) {
            for (let columnIndex = 0; columnIndex < reelWindow[rowIndex].length; columnIndex++) {
                const index = columnIndex * reelWindow.length + rowIndex;
                const replacementSymbol = indexToSymbolMap[index];
                if (replacementSymbol !== undefined) {
                    reelWindow[rowIndex][columnIndex] = replacementSymbol;
                }
            }
        }

        return reelWindow;
    }

    function processWinlinesTable(stage, winningLine) {

        let payline = winningLine?.payline === undefined
            ? winningLine.indexes
            : winningLine?.payline === -1
                ? winningLine.indexes
                : payLines(gameName)[winningLine?.payline];

        if(winningLine.direction === 'right-to-left') {
            payline = [...payline].reverse()
        }

        const highlightIndexes = {}

        stage?.stages?.[0].payouts?.scatter_values?.forEach(scatter => {
            if(scatter.indexes?.length > 0) {
                scatter.indexes.forEach(index => {
                    highlightIndexes[index] = scatter.symbol;
                });
            }

        });

        return (
            <tbody>
                    {rows?.map((row, rowKey) => (
                        <tr key={rowKey}>
                            {stage?.reel_window?.length > 0 ?
                                stage?.reel_window?.map((column, columnKey) => {
                                const symbol = column[row];
                                const symbolIndex = columnKey + rowKey * stage?.reel_window.length;
                                const partOfPayline = payline?.slice(0, winningLine.count);
                                let isSymbolInWinLine = partOfPayline?.includes(symbolIndex);

                                    if (stage?.bonus_game !== null) {
                                        if (symbol === "S") {
                                            isSymbolInWinLine = true;
                                        }
                                    }

                                    if (highlightIndexes[symbolIndex]) {
                                        isSymbolInWinLine = true;
                                    }

                                const brightness = isSymbolInWinLine ? 1 : 0.4;

                                return (
                                    <td
                                        key={columnKey}
                                        style={{
                                            filter: `brightness(${brightness})`,
                                            backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                                gameName,
                                                isAllowed
                                            )}/${symbol}.png)`,
                                            position: 'relative',
                                        }}
                                    >
                                        {!imageLinkName(gameName, isAllowed) && (symbol ? String(symbol).toUpperCase() : '')}
                                    </td>
                                );
                            }) : stage?.window?.length > 0 ?
                                    stage?.window?.map((column, columnKey) => {
                                        const symbol = column[row];
                                        const symbolIndex = columnKey + rowKey * stage?.window.length;
                                        const partOfPayline = payline.slice(0, winningLine.count);
                                        let isSymbolInWinLine = partOfPayline.includes(symbolIndex);

                                        if (highlightIndexes[symbolIndex]) {
                                            isSymbolInWinLine = true;
                                        }

                                        const brightness = isSymbolInWinLine ? 1 : 0.4;

                                        return (
                                            <td
                                                key={columnKey}
                                                style={{
                                                    filter: `brightness(${brightness})`,
                                                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                                        gameName,
                                                        isAllowed
                                                    )}/${symbol}.png)`,
                                                    position: 'relative',
                                                }}
                                            >
                                                {!imageLinkName(gameName, isAllowed) && (symbol ? String(symbol).toUpperCase() : '')}
                                            </td>
                                        );
                                    })
                                 : stage?.stages?.[0].reel_window?.map((column, columnKey) => {
                                        const symbol = column[row];
                                        const symbolIndex = columnKey + rowKey * stage?.stages?.[0].reel_window.length;
                                        const partOfPayline = payline?.slice(0, winningLine.count);
                                        let isSymbolInWinLine = partOfPayline?.includes(symbolIndex);

                                        if (highlightIndexes[symbolIndex]) {
                                            isSymbolInWinLine = true;
                                        }

                                        const brightness = isSymbolInWinLine ? 1 : 0.4;

                                        return (
                                            <td
                                                key={columnKey}
                                                style={{
                                                    filter: `brightness(${brightness})`,
                                                    backgroundImage: `url(https://s3.fr-par.scw.cloud/heronbyte.cdn/games/${imageLinkName(
                                                        gameName,
                                                        isAllowed
                                                    )}/${symbol}.png)`,
                                                    position: 'relative',
                                                }}
                                            >
                                                {!imageLinkName(gameName, isAllowed) && (symbol ? String(symbol).toUpperCase() : '')}
                                            </td>
                                        );
                                    })}
                        </tr>
                    ))}
            </tbody>
        );
    }

    function getTimezoneName() {
        const today = new Date();
        const short = today.toLocaleDateString(undefined);
        const full = today.toLocaleDateString(undefined, {timeZoneName: "long"});

        const shortIndex = full.indexOf(short);
        if (shortIndex >= 0) {
            const trimmed =
                full.substring(0, shortIndex) +
                full.substring(shortIndex + short.length);
            return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "");
        } else {
            return full;
        }
    }

    const bonusGame =
        (isAllowed &&
            data?.spin?.spins?.[0]?.stages?.filter(item => item?.bonus_game)?.length >
            0) ||
        data?.spin?.bonus_spins?.length > 0 ||
        data?.spin?.bonus?.spins_results?.length > 0 ||
        data?.spin?.bonus?.spins?.length > 0 ||
        data?.spin?.bonus?.length > 0;

    const isFSRetriggered = () => {
        if (!isAllowed) return;
        let lastValue = null;
        let isRetriggered = false;
        data?.spin?.spins?.[0]?.stages?.forEach(item => {
            if (!item?.bonus_game || isRetriggered) return;
            item?.bonus_game?.spins?.forEach((spin, key) => {
                if (
                    key === item?.bonus_game?.spins?.length - 1 &&
                    lastValue > spin?.free_spins_left
                ) {
                    lastValue = null;
                } else {
                    if (key === 0) {
                        lastValue = spin?.free_spins_left;
                        return;
                    }
                    if (lastValue < spin?.free_spins_left) {
                        isRetriggered = true;
                    } else {
                        lastValue = spin?.free_spins_left;
                    }
                }
            });
        })
        return isRetriggered ? "Yes" : "No";
    };

    const  gambles = () => {
        return data?.spin?.gambles || [];
    };

    return (
        <div
            className={`result-table p-2 radius-10 overflow-${
                isAllowed ? "scroll" : "hidden"
            }${isAllowed ? "" : " not-available"}`}
        >
            <div id="main">
                <div className="header">
                    <div className="header__name" id="gameName">
                        {data?.game?.name}
                    </div>
                </div>
                <div className="game-state">
                    <table>
                        <tbody>
                        <tr>
                            <td>Game Round ID</td>
                            <td id="gameStateID">{data?.id}</td>
                        </tr>
                        <tr>
                            <td>Game Status</td>
                            <td id="gameStatus">
                                {bonusGame ? "Free spin Game" : "Base Game"}
                            </td>
                        </tr>
                        {shouldShowBonusTriggered(gameName) && (
                            <tr>
                                <td>Free spin Triggered</td>
                                <td id="isBonus">{bonusGame ? "Yes" : "No"}</td>
                            </tr>
                        )}
                        <tr>
                            <td>Date/Time</td>
                            <td id="dateTime">
                                {moment(data?.created_at).format(
                                    "ddd MMM DD YYYY HH:mm:ss UTCZ"
                                )}{" "}
                                ({getTimezoneName()})
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="game-stats-info">
                    <table className="game-stats-info__stats-class">
                        <thead>
                        <tr>
                            <td colSpan="2">Game Statistics</td>
                        </tr>
                        </thead>
                        <tbody>
                        {gameName !== "plinko" || gameName !== "asgardparty" || gameName !== "ego-draconis" && (
                            <tr>
                                <td>Total Payline</td>
                                <td id="totalPayline">
                                    {paylines(gameName, data, isAllowed) || " - "}
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td>{gameName === "plinko" ? "Current " : "Total "} Wager</td>
                            <td id="totalBet">{data?.wager}</td>
                        </tr>
                        {gameName !== "plinko" && (
                            <tr>
                                <td>Total Award</td>
                                <td id="totalWin">{data?.base_award + data?.bonus_award}</td>
                            </tr>
                        )}
                        <tr>
                            <td>Base award</td>
                            <td id="currentWin">
                                {data?.base_award}
                            </td>
                        </tr>
                        {shouldShowRetriggerFreeSpin(gameName) && (
                            <tr>
                                <td>Free spin Re-triggered</td>
                                <td id="retrigerFreespin">{isFSRetriggered()}</td>
                            </tr>
                        )}
                        <tr>
                            <td>Currency</td>
                            <td id="currency">{data?.currency?.toUpperCase()}</td>
                        </tr>
                        {shouldShowBonusSpinCounter(gameName) && (
                            <tr id="freespinCounterInfo">
                                <td>Free Spin Counter:</td>
                                <td id="freespinCounterNum">
                                    {bonus_spin_counter(gameName, data, isAllowed) || " 0 "}
                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                    {stops(gameName, data, isAllowed)?.length > 0 && (
                        <>
                            <p className="tx-16 tx-bold mt-4 mb-2">Stops</p>
                            <table
                                className="game-stats-info__stats-class mt-0 mb-4"
                                id="win_lines_table"
                            >
                                <thead>
                                <tr>
                                    <td colSpan="6">Stops</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    {stops(gameName, data, isAllowed)?.map((pLine, itemKey) => (
                                        <td key={itemKey}>{pLine}</td>
                                    ))}
                                </tr>
                                </tbody>
                            </table>
                        </>
                    )}
                    {gambles()?.length > 0 && (
                        <>
                            <p className="tx-16 tx-bold mt-4 mb-2">Gambles</p>
                            <table
                                className="game-stats-info__stats-class mt-0 mb-4"
                                id="win_lines_table"
                            >
                                {gambles()?.map((gamble, key) => (
                                    <React.Fragment key={key}>
                                        <thead>
                                        <tr>
                                            <td colSpan="4">Gamble - {key + 1}</td>
                                        </tr>
                                        <tr>
                                            <td>Expected color</td>
                                            <td>Real color</td>
                                            <td>Wager</td>
                                            <td>Award</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>
                                                {gamble?.["ExpectColor"] === "r" ? "Red" : "Black"}
                                            </td>
                                            <td>
                                                {gamble?.["RealColor"] === "r" ? "Red" : "Black"}
                                            </td>
                                            <td>
                                                {convertToCurrency(
                                                    Number(gamble?.["wager"]) / 1000,
                                                    data?.currency
                                                )}
                                            </td>
                                            <td>
                                                {convertToCurrency(
                                                    Number(gamble?.["award"]) / 1000,
                                                    data?.currency
                                                )}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </React.Fragment>
                                ))}
                            </table>
                        </>
                    )}
                    {gameName === "burning-20-wins" && (
                        <>
                            <table
                                className="game-stats-info__stats-class mt-0 mb-4"
                                id="win_lines_table"
                            >
                                <thead>
                                <tr>
                                    <td>Has expanded wild</td>
                                    <td>
                                        {data?.spin?.is_expended_wild ? "True" : "False"}
                                    </td>
                                </tr>
                                </thead>
                            </table>
                            <table
                                className="game-stats-info__stats-class mt-0 mb-4"
                                id="win_lines_table"
                            >
                                <thead>
                                <tr>
                                    <td colSpan="4">Reeltype</td>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td colSpan="2">
                                        {data?.spin?.reels_type
                                            ? "High RTP Reeelcode"
                                            : "Low RTP Reelcode"}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </>
                    )}
                    {
                        (
                            data.spin?.amount > 0 ||
                            data.spin?.total_win > 0 ||
                            data.spin?.base_award > 0 ||
                            data.spin?.award > 0 ||
                            data.spin?.base_win > 0 ? (
                                        <p className="tx-16 tx-bold mt-4 mb-2">Reels</p>
                            ) : null
                        )
                    }

                    <div className="Withcontrols">
                        <Carousel interval={null}
                                  controls={stages(gameName, data, isAllowed).length > 1 &&
                                      (gameName !== 'stonesofmagic' && gameName !== 'secrettotems')}
                        >
                            {stages(gameName, data, isAllowed)?.map((stage, key) => {
                                let currentFigures = [];
                                let currentTopFigures = [];

                                for (let i = 0; i <= key; i++) {
                                    const currentStage = stages(gameName, data, isAllowed)?.[i];
                                    const {
                                        new_figures_position,
                                        new_reel_figures,
                                        payouts,
                                        new_top_figures
                                    } = currentStage || {};

                                    if (new_reel_figures && new_reel_figures.length > 0) {
                                        new_reel_figures.forEach((newFigure) => {
                                            const duplicateFigures = currentFigures.filter(
                                                (figSet) => figSet[0]?.x === newFigure.x && figSet[0]?.y === newFigure.y
                                            );

                                            if (duplicateFigures.length > 0) {
                                                duplicateFigures.forEach((duplicateSet) => {
                                                    duplicateSet.push(newFigure);
                                                });
                                            } else {
                                                currentFigures.push([newFigure]);
                                            }
                                        });
                                    }

                                    if (new_top_figures && new_top_figures.length > 0) {
                                        new_top_figures.forEach((newFigure) => {
                                            currentTopFigures.findIndex(
                                                (topFig) => topFig.x === newFigure.x
                                            );
                                            currentTopFigures.push(newFigure);
                                        });
                                    }

                                    if (new_figures_position) {
                                        currentFigures = currentFigures.map((figureSet) => {
                                            if (i !== key) {
                                                return figureSet.map((figure) => {
                                                    const newPosition = new_figures_position.find((newFigure) => newFigure.id === figure?.id);
                                                    if (newPosition) {
                                                        return {
                                                            ...figure,
                                                            y: newPosition.y,
                                                        };
                                                    } else {
                                                        return figure;
                                                    }
                                                });
                                            } else {
                                                return figureSet;
                                            }
                                        });

                                        currentTopFigures = currentTopFigures.map((figure) => {
                                            const newPosition = new_figures_position.find((newTopFigure) => newTopFigure.id === figure.id);
                                            if (newPosition) {
                                                return {
                                                    ...figure,
                                                    x: newPosition.x,
                                                };
                                            } else {
                                                return figure;
                                            }
                                        });
                                    }

                                    if (i !== key) {
                                        const payoutFigureIds = [];

                                        currentFigures = currentFigures.map((figureSet) => {
                                            return figureSet.filter(
                                                (fig) => !fig?.is_special || fig.name === 'f' || fig.name === 'w'
                                            );
                                        });

                                        currentTopFigures = currentTopFigures.filter(
                                            (topFig) => !topFig.is_special || topFig.name === 'f' || topFig.name === 'w'
                                        );

                                        payouts?.values?.forEach((payout) => {
                                            if (payout.figures) {
                                                payoutFigureIds.push(...payout.figures);
                                            }
                                        });

                                        currentFigures = currentFigures.map((figureSet) => {
                                            return figureSet.filter(
                                                (fig) =>
                                                    !payoutFigureIds.includes(fig?.id) &&
                                                    (!fig?.is_special || fig.name === 'f' || fig.name === 'w')
                                            );
                                        });

                                        currentTopFigures = currentTopFigures.filter(
                                            (topFig) =>
                                                !payoutFigureIds.includes(topFig.id) &&
                                                (!topFig.is_special || topFig.name === 'f' || topFig.name === 'w')
                                        );
                                    }
                                }

                                const tableRows = processResultTable(stage, currentFigures, currentTopFigures);
                                if (
                                    (data.spin?.amount > 0 ||
                                        data.spin?.total_win > 0 ||
                                        data.spin?.base_award > 0 ||
                                        data.spin?.award > 0 ||
                                        data.spin?.base_win > 0) &&
                                    (gameName !== 'asgardparty' &&
                                    gameName !== 'ego-draconis')) {
                                    if(stages(gameName, data, isAllowed)?.length > 1) {
                                        return <Accordion>
                                            <Accordion.Item eventKey={key}>
                                                <Accordion.Header>
                                                    Stage - {key + 1}
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    {stage.payouts?.values?.length >= 1 ? (
                                                        <Carousel interval={null} controls={stage.payouts?.values?.length > 1}>
                                                            {stage.payouts?.values?.map((value, key) => (
                                                                <Carousel.Item key={key}>
                                                                    <table className={`game-stats-info__stats-class mt-0`} id="symbols_table">
                                                                        <thead>
                                                                        <tr className="text-center">
                                                                            {(stage?.reel_window?.length > 0 || stage?.payouts !== 0) && (
                                                                                <td colSpan={5} className="py-3">
                                                                                    Winline №{value.payline + 1}
                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                        </thead>
                                                                        {processWinlinesTable(stage, value)}
                                                                    </table>
                                                                </Carousel.Item>
                                                            ))}
                                                        </Carousel>
                                                    ) : (
                                                        <table className={`game-stats-info__stats-class mt-0`} id="symbols_table">
                                                            <thead>
                                                            <tr className="text-center">
                                                                {(stage?.reel_window?.length > 0 || stage?.payouts !== 0) && (
                                                                    <td colSpan={5} className="py-3">
                                                                        Table - {key + 1}
                                                                    </td>
                                                                )}
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {tableRows}
                                                            </tbody>
                                                        </table>
                                                    )}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    } else {
                                        console.log(stage?.payouts?.values)
                                        return stage?.payouts?.values?.length >= 1 ?
                                            <Carousel interval={null} controls={stage?.payouts?.values?.length > 1}>
                                                {stage?.payouts?.values?.map((value, key) => (
                                                    <Carousel.Item key={key}>
                                                        <table
                                                            className={`game-stats-info__stats-class mt-0`}
                                                            id="symbols_table">
                                                            <thead>
                                                            <tr className="text-center">
                                                                {(stage?.reel_window?.length > 0) && (
                                                                    <td colSpan={5} className="py-3">
                                                                        Winline №{value.payline + 1 || "0"}
                                                                    </td>
                                                                )}
                                                            </tr>
                                                            </thead>
                                                            {processWinlinesTable(stage, stage?.payouts?.values?.[key])}
                                                        </table>
                                                    </Carousel.Item>
                                                ))}
                                            </Carousel>
                                            :
                                            <Carousel.Item key={key}>
                                                <table className={`game-stats-info__stats-class mt-0 ${gameName === "asgardparty" || gameName === "ego-draconis" ? "narrow-table" : ""}`} id="symbols_table">
                                                    <thead>
                                                    <tr className="text-center">
                                                        <td colSpan={5}
                                                            className="py-3">
                                                            Table - {key + 1}
                                                        </td>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {tableRows}
                                                    </tbody>
                                                </table>
                                            </Carousel.Item>
                                    }
                                } else {
                                   return <Carousel.Item key={key}>
                                                    <table className={`game-stats-info__stats-class mt-0 ${gameName === "asgardparty" || gameName === "ego-draconis" ? "narrow-table" : ""}`} id="symbols_table">
                                                        <thead>
                                                        <tr className="text-center">
                                                            {(stage?.reel_window?.length > 0 || stage?.payouts !== 0) && (
                                                                <td colSpan={gameName === "asgardparty" || gameName === "ego-draconis" ? "7" : "5"}
                                                                    className="py-3">
                                                                    Table - {key + 1}
                                                                </td>
                                                            )}
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {tableRows}
                                                        </tbody>
                                                    </table>
                                                </Carousel.Item>
                                            }
                                        })}
                                    </Carousel>

                        {stages(gameName, data, isAllowed)?.map((stage) => {
                            return stage?.bonus_game ? (
                                <>
                                    <p className="tx-16 tx-bold mt-4 mb-2">
                                        Free spins reels
                                    </p>
                                    <Accordion>
                                        {stage?.bonus_game?.spins?.map((spin, key) => {
                                            return (
                                                <Accordion.Item eventKey={key}>
                                                    <Accordion.Header>
                                                        Free spin - {key + 1}
                                                    </Accordion.Header>
                                                    <Accordion.Body>
                                                        <Carousel interval={null} controls={spin?.stages?.length > 1}>
                                                            {spin?.stages?.map((stage, key) => {
                                                                let currentFigures = [];
                                                                let currentTopFigures = [];

                                                                for (let i = 0; i <= key; i++) {
                                                                    const currentStage = spin?.stages?.[i];
                                                                    const {
                                                                        new_figures_position,
                                                                        new_reel_figures,
                                                                        payouts,
                                                                        new_top_figures
                                                                    } = currentStage || {};

                                                                    if (new_reel_figures && new_reel_figures.length > 0) {
                                                                        new_reel_figures.forEach((newFigure) => {
                                                                            const duplicateFigures = currentFigures.filter(
                                                                                (figSet) => figSet[0]?.x === newFigure.x && figSet[0]?.y === newFigure.y
                                                                            );

                                                                            if (duplicateFigures.length > 0) {
                                                                                duplicateFigures.forEach((duplicateSet) => {
                                                                                    duplicateSet.push(newFigure);
                                                                                });
                                                                            } else {
                                                                                currentFigures.push([newFigure]);
                                                                            }
                                                                        });
                                                                    }

                                                                    if (new_top_figures && new_top_figures.length > 0) {
                                                                        new_top_figures.forEach((newFigure) => {
                                                                            currentTopFigures.findIndex(
                                                                                (topFig) => topFig.x === newFigure.x
                                                                            );
                                                                            currentTopFigures.push(newFigure);
                                                                        });
                                                                    }

                                                                    if (new_figures_position) {
                                                                        currentFigures = currentFigures.map((figureSet) => {
                                                                            if (i !== key) {
                                                                                return figureSet.map((figure) => {
                                                                                    const newPosition = new_figures_position.find((newFigure) => newFigure.id === figure?.id);
                                                                                    if (newPosition) {
                                                                                        return {
                                                                                            ...figure,
                                                                                            y: newPosition.y,
                                                                                        };
                                                                                    } else {
                                                                                        return figure;
                                                                                    }
                                                                                });
                                                                            } else {
                                                                                return figureSet;
                                                                            }
                                                                        });

                                                                        currentTopFigures = currentTopFigures.map((figure) => {
                                                                            const newPosition = new_figures_position.find((newTopFigure) => newTopFigure.id === figure.id);
                                                                            if (newPosition) {
                                                                                return {
                                                                                    ...figure,
                                                                                    x: newPosition.x,
                                                                                };
                                                                            } else {
                                                                                return figure;
                                                                            }
                                                                        });
                                                                    }


                                                                    if (i !== key) {
                                                                        const payoutFigureIds = [];

                                                                        currentFigures = currentFigures.map((figureSet) => {
                                                                            return figureSet.filter(
                                                                                (fig) => !fig?.is_special || fig.name === 'f' || fig.name === 'w'
                                                                            );
                                                                        });

                                                                        currentTopFigures = currentTopFigures.filter(
                                                                            (topFig) => !topFig.is_special || topFig.name === 'f' || topFig.name === 'w'
                                                                        );

                                                                        payouts?.values?.forEach((payout) => {
                                                                            if (payout.figures) {
                                                                                payoutFigureIds.push(...payout.figures);
                                                                            }
                                                                        });

                                                                        currentFigures = currentFigures.map((figureSet) => {
                                                                            return figureSet.filter(
                                                                                (fig) =>
                                                                                    !payoutFigureIds.includes(fig?.id) &&
                                                                                    (!fig?.is_special || fig.name === 'f' || fig.name === 'w')
                                                                            );
                                                                        });

                                                                        currentTopFigures = currentTopFigures.filter(
                                                                            (topFig) =>
                                                                                !payoutFigureIds.includes(topFig.id) &&
                                                                                (!topFig.is_special || topFig.name === 'f' || topFig.name === 'w')
                                                                        );
                                                                    }
                                                                }

                                                                const bonusTableRows = processBonusTable(stage, currentFigures, currentTopFigures)
                                                                if (
                                                                    (spin?.amount > 0 ||
                                                                        spin?.total_win > 0 ||
                                                                        spin?.base_award > 0 ||
                                                                        spin?.award > 0 ||
                                                                        spin?.base_win > 0 ||
                                                                            spin?.payouts_info?.spin_payouts !== null
                                                                    ) &&
                                                                    (gameName !== 'asgardparty' &&
                                                                        gameName !== 'ego-draconis')) {
                                                                    if(spin?.stages?.length > 1) {
                                                                        return <Accordion>
                                                                            <Accordion.Item eventKey={key}>
                                                                                <Accordion.Header>
                                                                                    Stage - {key + 1}
                                                                                </Accordion.Header>
                                                                                <Accordion.Body>
                                                                                    {stage.payouts?.values?.length >= 1 ? (
                                                                                        <Carousel interval={null} controls={stage.payouts?.values?.length > 1}>
                                                                                            {stage.payouts?.values?.map((value, key) => (
                                                                                                <Carousel.Item key={key}>
                                                                                                    <table className={`game-stats-info__stats-class mt-0`} id="symbols_table">
                                                                                                        <thead>
                                                                                                        <tr className="text-center">
                                                                                                            {(stage?.reel_window?.length > 0 || stage?.payouts !== 0) && (
                                                                                                                <td colSpan={5} className="py-3">
                                                                                                                    Winline №{value.payline + 1}
                                                                                                                </td>
                                                                                                            )}
                                                                                                        </tr>
                                                                                                        </thead>
                                                                                                        {processWinlinesTable(stage, value)}
                                                                                                    </table>
                                                                                                </Carousel.Item>
                                                                                            ))}
                                                                                        </Carousel>
                                                                                    ) : (
                                                                                        <table className={`game-stats-info__stats-class mt-0`} id="symbols_table">
                                                                                            <thead>
                                                                                            <tr className="text-center">
                                                                                                {(stage?.reel_window?.length > 0 || stage?.payouts !== 0) && (
                                                                                                    <td colSpan={5} className="py-3">
                                                                                                        Table - {key + 1}
                                                                                                    </td>
                                                                                                )}
                                                                                            </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                            {bonusTableRows}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    )}
                                                                                </Accordion.Body>
                                                                            </Accordion.Item>
                                                                        </Accordion>
                                                                    } else {
                                                                   return stage.payouts?.values?.length >= 1 ? (
                                                                        <Carousel interval={null} controls={stage.payouts?.values?.length > 1}>
                                                                            {stage.payouts?.values?.map((value, key) => {
                                                                               return <Carousel.Item key={key}>
                                                                                    <table className={`game-stats-info__stats-class mt-0`} id="symbols_table">
                                                                                        <thead>
                                                                                        <tr className="text-center">
                                                                                                <td colSpan={5} className="py-3">
                                                                                                    Winline №{value.payline + 1 || "0"}
                                                                                                </td>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        {processWinlinesTable(spin, value)}
                                                                                    </table>
                                                                                </Carousel.Item>
                                                                            })}
                                                                        </Carousel>
                                                                    ) : (
                                                                       <Carousel.Item key={key}>
                                                                        <table className={`game-stats-info__stats-class mt-0`} id="symbols_table">
                                                                            <thead>
                                                                            <tr className="text-center">
                                                                                {(stage?.reel_window?.length > 0) && (
                                                                                    <td colSpan={5} className="py-3">
                                                                                        Table - {key + 1}
                                                                                    </td>
                                                                                )}
                                                                            </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                            {bonusTableRows}
                                                                            </tbody>
                                                                        </table>
                                                                       </Carousel.Item>
                                                                   )}
                                                                } else {
                                                                    return <Carousel.Item key={key}>
                                                                                <table className={`game-stats-info__stats-class mt-0 ${gameName === 'asgardparty' || gameName === 'ego-draconis' ? 'narrow-table' : ''}`} id="symbols_table">
                                                                                    <thead>
                                                                                    <tr className="text-center">
                                                                                        <td colSpan={gameName === 'asgardparty' || gameName === 'ego-draconis' ? 7 : 5} className="py-3">
                                                                                            Table - {key + 1}
                                                                                        </td>
                                                                                    </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                    {bonusTableRows}
                                                                                    </tbody>
                                                                                </table>
                                                                            </Carousel.Item>
                                                                }
                                                            })}
                                                        </Carousel>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            );
                                        })}
                                    </Accordion>
                                </>
                            ) : (
                                ""
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
