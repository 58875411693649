import React from "react";
import { getUserProfile } from "../services";
import { useQuery } from "react-query";
import moment from "moment";
import Loader from "../components/Loader/Loader";

export const ProfileContext = React.createContext(null);

export const useProfile = () => {
  const context = React.useContext(ProfileContext);

  if (context === undefined) {
    throw new Error(
      "useProfile can only be used inside CurrencyContextProvider"
    );
  }

  return context;
};

export const ProfileContextProvider = ({ children }) => {
  const { data, isLoading } = useQuery(["profile"], getUserProfile, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: Boolean(
      window
        ? window.localStorage.getItem("access_token") &&
            moment(window.localStorage.getItem("expired_at")).diff(moment()) > 0
        : false
    ),
    onError: data => {
      if (data?.response?.data?.status === 401) {
        window.localStorage.removeItem("access_token");
        window.localStorage.removeItem("refresh_token");
        window.localStorage.removeItem("expired_at");
        window.location.reload();
      }
    },
  });

  return (
    <ProfileContext.Provider
      value={{
        profileData: data?.account,
        organizationId: data?.organization_id,
      }}
    >
      {isLoading ? <Loader /> : children}
    </ProfileContext.Provider>
  );
};
