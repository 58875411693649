import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Modal, Row, Spinner} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useMutation} from "react-query";
import {createWagerSet} from "../../../../services";
import {Slide, toast} from "react-toastify";

const CreateModal = ({createModal, closeModal, onSettled}) => {
    const [wagerMeanings, setWagerMeanings] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [minWager, setMinWager] = useState(0);
    const [maxWager, setMaxWager] = useState(0);
    const [defaultWagerError, setDefaultWagerError] = useState("");

    const {
        getValues,
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            name: "",
            default_wager: "",
            wager_levels: [],
        },
    });

    const {mutate} = useMutation(createWagerSet, {
        onSuccess: () => {
            toastAlert("success");
            onSettled();
            setLoading(false);
            closeModal("createModal");
        },
        onError: () => {
            toastAlert("error");
            setLoading(false);
            closeModal("createModal");
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Wager set was created successfully`
                    : `Couldn't create wager set`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const onSubmit = handleSubmit(formData => {
        formData.wager_levels = wagerMeanings;
        formData.wager_levels = formData.wager_levels.map(value => parseInt(value));
        formData.default_wager = parseInt(formData.default_wager);
        if(!formData.wager_levels.includes(formData.default_wager)) {
            setDefaultWagerError("Invalid default wager");
            return;
        }
        setLoading(true);
        console.log(formData)
        mutate(formData);
        reset();
        setMaxWager(0);
        setMinWager(0);
        setDefaultWagerError('')
    });

    const handleValuesChange = e => {
        const values = e.target.value.split(",").map(val => val.trim());
        setWagerMeanings(values);
    };

    useEffect(() => {
        if (wagerMeanings.length > 0) {
            setMinWager(Math.min(...wagerMeanings));
            setMaxWager(Math.max(...wagerMeanings));
        } else {
            setMinWager(null);
            setMaxWager(null);
        }
    }, [wagerMeanings]);

    return (
        <Modal
            size="lg"
            show={createModal}
            aria-labelledby="example-modal-sizes-title-sm"
            centered>
            <Modal.Header>
                <Modal.Title>Wager sets creation</Modal.Title>
                <Button
                    variant=""
                    className="btn btn-close ms-auto"
                    onClick={() => {
                        closeModal("createModal")
                        reset();
                        setMaxWager(0);
                        setMinWager(0);
                        setDefaultWagerError('')
                    }}
                >
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className='wager-sets-modal'>
                    <Modal.Body>
                        <Container fluid>
                            <Form
                                className={`wd-100p needs-validation${isSubmitted ? "" : ""}`}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="form-group">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                placeholder="Enter name"
                                                type="text"
                                                {...register("name", {
                                                    required: true,
                                                    validate: value => value?.trim() !== "",
                                                })}
                                                isInvalid={!!errors?.name}
                                                isValid={isSubmitted && !errors?.name}
                                            />
                                            {errors?.name && (
                                                <Form.Control.Feedback type="invalid">
                                                    Name is required
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Default wager</Form.Label>
                                            <Form.Control
                                                placeholder="Enter default wager"
                                                type="text"
                                                {...register("default_wager", {
                                                    required: true,
                                                    validate: (value) => {
                                                        const wagerLevels = getValues("wager_levels") || [];
                                                        return wagerLevels.includes(parseInt(value)) || "Invalid default wager";
                                                    }
                                                })}
                                                isInvalid={!!errors?.default_wager || !!defaultWagerError}
                                                isValid={isSubmitted && !errors?.default_wager}
                                            />
                                            {errors?.default_wager && (
                                                <Form.Control.Feedback type="invalid">
                                                    Invalid default wager
                                                </Form.Control.Feedback>
                                            )}
                                            {defaultWagerError && (
                                                <Form.Control.Feedback type="invalid">
                                                    Wager is out of wager levels
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group className="form-group">
                                            <Form.Label className="">Values</Form.Label>
                                            <Form.Control
                                                placeholder="Enter wager values"
                                                type="text"
                                                onChange={handleValuesChange}
                                                onInput={handleValuesChange}
                                                {...register("wager_levels", {
                                                    required: true,
                                                    validate: value => {
                                                        const values = value?.trim().split(",");
                                                        const isValid = values.every(val => !isNaN(val.trim()));
                                                        if (isValid) {
                                                            setWagerMeanings(values.map(val => val.trim()));
                                                        }
                                                        return isValid;
                                                    },
                                                })}
                                                isInvalid={!!errors?.wager_levels}
                                                isValid={isSubmitted && !errors?.wager_levels}
                                            />
                                            {errors?.wager_levels && (
                                                <div className="invalid-feedback">Wager levels are invalid</div>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Min wager</Form.Label>
                                            <Form.Control
                                                disabled
                                                placeholder="Min wager"
                                                type="text"
                                                value={minWager !== null ? minWager : 0}
                                            />
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Max wager</Form.Label>
                                            <Form.Control
                                                disabled
                                                placeholder="Max wager"
                                                type="text"
                                                value={maxWager !== null ? maxWager : 0}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Modal.Footer>
                                    <Button
                                        disabled={loading}
                                        variant="primary"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            closeModal("createModal");
                                            reset();
                                            setMaxWager(0);
                                            setMinWager(0);
                                            setDefaultWagerError('')
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CreateModal;