import httpClient from "./httpClient";

export const getPermissons = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(`/permissions?${params.toString()}`);
  if (data) {
    return { data: data.data, total: data?.meta?.total };
  }
};

export const addRolePermission = async formData => {
  const { data } = await httpClient.post(`/roles/${formData?.id}/permissions`, {
    permissions: formData?.permissions,
  });
  if (data) {
    return data.data;
  }
};
export const deleteRolePermission = async formData => {
  const { data } = await httpClient.delete(
    `/roles/${formData?.id}/permissions`,

    {
      data: {
        permissions: formData?.permissions,
      },
    }
  );
  if (data) {
    return data.data;
  }
};
