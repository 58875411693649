import React, {useState} from "react";
import {useMutation, useQuery} from "react-query";
import {Button, Card, Col, Form, Nav, Row, Spinner, Tab, Accordion, Container, FormGroup} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {useForm} from "react-hook-form";
import {createWagerSet} from "../../../services";
import {Slide, toast} from "react-toastify";

const GameDetail = () => {
    const {id} = useParams();

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const dummyData = [
        {
            name: "pokerdom",
            rtp: 94,
            wager_levels: [1, 2, 3, 4, 5, 10],
            default_wager: 2,
            max_wager: 10,
            min_wager: 1,
            pfr_enabled: true,
            bonus_enabled: false,
            gambles_enabled: false,
            jackpot_enabled: true,
            is_active: true,
            currencies: ["UAH", "USD"]
        },
        {
            name: "infin games",
            rtp: 91,
            wager_levels: [10, 20, 30, 40, 50, 100],
            default_wager: 20,
            max_wager: 100,
            min_wager: 10,
            pfr_enabled: false,
            bonus_enabled: false,
            gambles_enabled: true,
            jackpot_enabled: true,
            is_active: true,
            currencies: ["UAH", "USD"]
        }
    ]

    // const { data, isLoading } = useQuery(["game-detail", { id }], getSession, {
    //     refetchOnWindowFocus: false,
    //     refetchOnMount: false,
    // });
    //
    // const renderData = displayData => {
    //     if (isLoading) {
    //         return <Spinner size="sm" />;
    //     }
    //     if (data) {
    //         console.log(data)
    //         return displayData;
    //     }
    //     return " - ";
    // };

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            name: "",
            game_id: "",
        },
    });

    const {mutate} = useMutation(createWagerSet, {
        onSuccess: () => {
            toastAlert("success");
            //refetch
            setLoading(false);
        },
        onError: () => {
            toastAlert("error");
            setLoading(false);
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Wager set was created succesfuly`
                    : `Couldn't create wager set`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const onSubmit = handleSubmit(formData => {
        setLoading(true);
        console.log(formData)
        mutate(formData);
        reset();
    });

    return (
        <React.Fragment>
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Game Name
          </span>
                </div>
            </div>
            <Card className='wd-90p mg-l-40'>
                <Card.Body className="custom-card">
                    <div className="text-wrap">
                        <div className="border">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="link-29">
                                <div className="bg-gray-100 nav-bg">

                                    <Nav className="nav nav-tabs">
                                        <Nav.Item>
                                            <Nav.Link eventKey="link-29">
                                                General info
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="link-30">
                                                Integrators
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>

                                </div>
                                <Tab.Content className="card-body ">
                                    <Tab.Pane eventKey="link-29">
                                        <Form
                                            className={`wd-100p needs-validation${isSubmitted ? "" : ""}`}
                                            onSubmit={handleSubmit(onSubmit)}
                                        >
                                            <Row>
                                                <Col lg={6}>
                                                    <Form.Group className="form-group wd-75p">
                                                        <Form.Label>Name</Form.Label>
                                                        <Form.Control
                                                            placeholder="Enter name"
                                                            type="text"
                                                            {...register("name", {
                                                                required: true,
                                                                validate: value => value?.trim() !== "",
                                                            })}
                                                            isInvalid={!!errors?.name}
                                                            isValid={isSubmitted && !errors?.name}
                                                        />
                                                        {errors?.name && (
                                                            <Form.Control.Feedback type="invalid">
                                                                Name is required
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                    <Form.Group className="form-group wd-75p">
                                                        <Form.Label>Game ID</Form.Label>
                                                        <Form.Control
                                                            placeholder="Enter game ID"
                                                            type="text"
                                                            {...register("game_id", {
                                                                required: true,
                                                                validate: value => value?.trim() !== "",
                                                            })}
                                                            isInvalid={!!errors?.game_id}
                                                            isValid={isSubmitted && !errors?.game_id}
                                                        />
                                                        {errors?.game_id && (
                                                            <Form.Control.Feedback type="invalid">
                                                                Game ID is required
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <div className='d-flex mg-t-100 wd-100p justify-content-center'>
                                                <Button
                                                    disabled={loading}
                                                    variant="primary"
                                                    type="submit"
                                                    className='mg-r-15'
                                                >
                                                    Save
                                                </Button>
                                                <Button
                                                    variant="secondary"
                                                    onClick={() => {
                                                        reset();
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="link-30">
                                        <Row className="accordiondclose">
                                            <Col lg={12} md={12}>
                                                <Card>
                                                    <Card.Body>
                                                        <Accordion defaultActiveKey="Item1" id="accordion">
                                                            {dummyData.map((item, index) => {
                                                                return <Accordion.Item eventKey={`Item${index + 1}`}
                                                                                       className="">
                                                                    <Accordion.Header
                                                                        className=""
                                                                        id="headingOne"
                                                                    >
                                                                        {item?.name?.toUpperCase()}
                                                                    </Accordion.Header>

                                                                    <Accordion.Body className="borders">
                                                                        <Form className='mg-l-50'>
                                                                            <Container>
                                                                                <Row>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Form.Group>
                                                                                            <Form.Label>RTP</Form.Label>
                                                                                            <Form.Control
                                                                                                type="text"
                                                                                                className="form-control form-control-sm wd-75p"
                                                                                                name="input"
                                                                                            />
                                                                                        </Form.Group>
                                                                                        <Form.Group>
                                                                                            <Form.Label>Wager
                                                                                                sets</Form.Label>
                                                                                            <Form.Control
                                                                                                type="text"
                                                                                                className="form-control form-control-sm wd-75p"
                                                                                                name="input"
                                                                                            />
                                                                                        </Form.Group>
                                                                                        <Form.Group>
                                                                                            <Form.Label>Default Wager</Form.Label>
                                                                                            <Form.Control
                                                                                                type="text"
                                                                                                className="form-control form-control-sm wd-75p"
                                                                                                name="input"
                                                                                            />
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Row>
                                                                                            <Col xs={12} md={6}>
                                                                                                <Row>
                                                                                                    <Col xs={6} md={12} className="d-flex">
                                                                                                        <FormGroup className="form-group d-flex justify-content-between">
                                                                                                            <Form.Label className="custom-switch ps-0 mb-0 me-2">
                                                                                                                Enable PFR
                                                                                                                <Form.Control
                                                                                                                    type="checkbox"
                                                                                                                    name="custom-switch-checkbox1"
                                                                                                                    className="custom-switch-input"
                                                                                                                />
                                                                                                                <span className="custom-switch-indicator mg-l-10"></span>
                                                                                                            </Form.Label>
                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    <Col xs={6} md={12} className="d-flex">
                                                                                                        <FormGroup className="form-group d-flex align-items-center">
                                                                                                            <Form.Label className="custom-switch ps-0 mb-0 me-2">
                                                                                                                Jackpot
                                                                                                                <Form.Control
                                                                                                                    type="checkbox"
                                                                                                                    name="custom-switch-checkbox2"
                                                                                                                    className="custom-switch-input"
                                                                                                                />
                                                                                                                <span className="custom-switch-indicator mg-l-30"></span>
                                                                                                            </Form.Label>
                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Col>
                                                                                            <Col xs={12} md={6}>
                                                                                                <Row>
                                                                                                    <Col xs={6} md={12} className="d-flex">
                                                                                                        <FormGroup className="form-group d-flex align-items-center">
                                                                                                            <Form.Label className="custom-switch ps-0 mb-0 me-2">
                                                                                                                Gambles
                                                                                                                <Form.Control
                                                                                                                    type="checkbox"
                                                                                                                    name="custom-switch-checkbox3"
                                                                                                                    className="custom-switch-input"
                                                                                                                />
                                                                                                                <span className="custom-switch-indicator mg-l-20"></span>
                                                                                                            </Form.Label>
                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                    <Col xs={6} md={12} className="d-flex">
                                                                                                        <FormGroup className="form-group d-flex align-items-center">
                                                                                                            <Form.Label className="custom-switch ps-0 mb-0 me-2">
                                                                                                                Buy bonus
                                                                                                                <Form.Control
                                                                                                                    type="checkbox"
                                                                                                                    name="custom-switch-checkbox4"
                                                                                                                    className="custom-switch-input"
                                                                                                                />
                                                                                                                <span className="custom-switch-indicator mg-l-10"></span>
                                                                                                            </Form.Label>
                                                                                                        </FormGroup>
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Col>
                                                                                        </Row>

                                                                                        <Row>
                                                                                            <Col xs={12}>
                                                                                                <Form.Group className='mg-t-50'>
                                                                                                    <Form.Label>Currency</Form.Label>
                                                                                                    <Form.Control
                                                                                                        type="text"
                                                                                                        className="form-control form-control-sm wd-75p"
                                                                                                        name="input"
                                                                                                    />
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Row>
                                                                                            <Col xs={12}>
                                                                                                <Form.Group>
                                                                                                    <Form.Label>Max wager (read only)</Form.Label>
                                                                                                    <Form.Control
                                                                                                        type="text"
                                                                                                        className="form-control form-control-sm wd-75p"
                                                                                                        name="input"
                                                                                                    />
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                    <Col xs={12} md={6}>
                                                                                        <Row>
                                                                                            <Col xs={12}>
                                                                                                <Form.Group>
                                                                                                    <Form.Label>Min Wager (read only)</Form.Label>
                                                                                                    <Form.Control
                                                                                                        type="text"
                                                                                                        className="form-control form-control-sm wd-75p"
                                                                                                        name="input"
                                                                                                    />
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                        </Row>
                                                                                    </Col>
                                                                                </Row>
                                                                                <Row>
                                                                                    <div
                                                                                        className='d-flex mg-t-100 wd-100p justify-content-center'>
                                                                                        <Button
                                                                                            disabled={loading}
                                                                                            variant="primary"
                                                                                            type="submit"
                                                                                            className='mg-r-15'
                                                                                        >
                                                                                            Save
                                                                                        </Button>
                                                                                        <Button
                                                                                            variant="secondary"
                                                                                            onClick={() => {
                                                                                                reset();
                                                                                            }}
                                                                                        >
                                                                                            Cancel
                                                                                        </Button>
                                                                                    </div>
                                                                                </Row>
                                                                            </Container>
                                                                        </Form>

                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            })}
                                                        </Accordion>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
};

export default GameDetail;
