import React from "react";
import moment from "moment";
import { Card, Col, Row, Collapse } from "react-bootstrap";
import {
  GeneralInfoBlock,
  RequestBlock,
  ResultBlock,
} from "./index";
import "./index.scss";

export const GameStateCollapse = ({ data }) => {
  return (
    <Card className="my-2 radius-10 bd px-4">
      <Card.Body className="spin-time-line transparent py-3">
        <Row className="px-0">
          <Col
            className="d-flex align-items-center bd-e"
            xl={3}
            lg={3}
            md={12}
            xs={12}
          >
              <i className="fa fa-gamepad tx-20" />
            <div className="tx-16 text-muted ms-2 tx-bold flex-1 text-truncate">
              {data?.id}
            </div>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center bd-e tx-14 text-muted"
            xl={2}
            lg={2}
            md={12}
            xs={12}
          >
            {moment(data?.created_at).format("DD-MM-YYYY HH:mm:ss UTC(Z)")}
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center bd-e tx-14 text-muted"
            xl={2}
            lg={2}
            md={12}
            xs={12}
          >
            Wager{" "}
            <span className="tx-bold ms-1">
              {data?.wager} {data?.currency?.toUpperCase()}
            </span>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center bd-e tx-14 text-muted"
            xl={2}
            lg={2}
            md={12}
            xs={12}
          >
            Award{" "}
            <span className="tx-bold ms-1">
              {data?.base_award + data?.bonus_award}{" "}
              {data?.currency?.toUpperCase()}
            </span>
          </Col>
          <Col
            className="d-flex align-items-center tx-14 text-muted"
            xl={3}
            lg={3}
            md={12}
            xs={12}
          >
            Status{" "}
            <span className="tx-bold ms-1">
              {data?.is_shown ? "SHOWN" : "NOT SHOWN"}
            </span>
          </Col>
        </Row>
          <div>
            <GeneralInfoBlock data={data} />
            <RequestBlock data={data} />
            <ResultBlock data={data} />
          </div>
      </Card.Body>
    </Card>
  );
};
