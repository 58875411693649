import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, Modal, Row, Spinner} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useMutation} from "react-query";
import {createWagerSet} from "../../../../services";
import {Slide, toast} from "react-toastify";

const CreateModal = ({createModal, closeModal, onSettled}) => {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            name: "",
            game_id: "",
        },
    });

    const {mutate} = useMutation(createWagerSet, {
        onSuccess: () => {
            toastAlert("success");
            onSettled();
            setLoading(false);
            closeModal("createModal");
        },
        onError: () => {
            toastAlert("error");
            setLoading(false);
            closeModal("createModal");
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Wager set was created succesfuly`
                    : `Couldn't create wager set`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const onSubmit = handleSubmit(formData => {
        setLoading(true);
        console.log(formData)
        mutate(formData);
        reset();
    });

    return (
        <Modal
            size="lg"
            show={createModal}
            aria-labelledby="example-modal-sizes-title-sm"
            centered>
            <Modal.Header>
                <Modal.Title>Game creation</Modal.Title>
                <Button
                    variant=""
                    className="btn btn-close ms-auto"
                    onClick={() => {
                        closeModal("createModal")
                        reset();
                    }}
                >
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                <div className='wager-sets-modal'>
                    <Modal.Body>
                        <Container fluid>
                            <Form
                                className={`wd-100p needs-validation${isSubmitted ? "" : ""}`}
                                onSubmit={handleSubmit(onSubmit)}
                                >
                                <Row>
                                    <Col lg={6}>
                                        <Form.Group className="form-group">
                                            <Form.Label>Name</Form.Label>
                                            <Form.Control
                                                placeholder="Enter name"
                                                type="text"
                                                {...register("name", {
                                                    required: true,
                                                    validate: value => value?.trim() !== "",
                                                })}
                                                isInvalid={!!errors?.name}
                                                isValid={isSubmitted && !errors?.name}
                                            />
                                            {errors?.name && (
                                                <Form.Control.Feedback type="invalid">
                                                    Name is required
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                        <Form.Group className="form-group">
                                            <Form.Label>Game ID</Form.Label>
                                            <Form.Control
                                                placeholder="Enter game ID"
                                                type="text"
                                                {...register("game_id", {
                                                    required: true,
                                                    validate: value => value?.trim() !== "",
                                                })}
                                                isInvalid={!!errors?.game_id}
                                                isValid={isSubmitted && !errors?.game_id}
                                            />
                                            {errors?.game_id && (
                                                <Form.Control.Feedback type="invalid">
                                                    Game ID is required
                                                </Form.Control.Feedback>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Modal.Footer className='mg-t-100'>
                                    <Button
                                        disabled={loading}
                                        variant="primary"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={() => {
                                            closeModal("createModal");
                                            reset();
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        </Container>
                    </Modal.Body>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CreateModal;