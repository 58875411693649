import httpClient from "./httpClient";

export const getGames = async () => {
  const { data } = await httpClient.get("/dictionaries/games");
  if (data) {
    return data.data;
  }
};

export const getIntegrators = async () => {
  const { data } = await httpClient.get("/dictionaries/integrators");
  if (data) {
    return data.data;
  }
};

export const getOperators = async () => {
  const { data } = await httpClient.get("/dictionaries/integrator-operators");
  if (data) {
    return data.data;
  }
};

export const getHosts = async () => {
  const { data } = await httpClient.get("/dictionaries/hosts");
  if (data) {
    return data.data;
  }
};

export const getCurrenciesData = async () => {
  const { data } = await httpClient.get("/dictionaries/currencies");
  if (data) {
    return data.data;
  }
};

export const getMainCurrencies = async () => {
  const { data } = await httpClient.get("/dictionaries/main-currencies");
  if (data) {
    return data.data;
  }
}
