import React from "react";
import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { createRole } from "../../../services";
import { toast, Slide } from "react-toastify";

export const RolesModal = ({ setShowModal, onSettled }) => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      type: "default",
    },
  });

  const { mutate } = useMutation(createRole, {
    onSuccess: () => {
      toastAlert("success");
      onSettled();
      setShowModal(false);
    },
    onError: () => {
      toastAlert("error");
      setShowModal(false);
    },
  });

  const toastAlert = type =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Role was created succesfuly`
          : `Couldn't create role`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const onSubmit = handleSubmit(formData => {
    setLoading(true);
    mutate(formData);
  });

  return (
    <Modal show onHide={() => setShowModal(false)} centered="true" size="md">
      <Modal.Header>
        <h6 className="modal-title">Add Role</h6>
        <Button variant="" type="button" onClick={() => setShowModal(false)}>
          <span aria-hidden="true" className="text-dark">
            X
          </span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <Form
          className={`needs-validation${isSubmitted ? "" : ""}`}
          onSubmit={e => {
            setIsSubmitted(true);
            onSubmit(e);
          }}
        >
          <Form.Group className="form-group">
            <Form.Label>Name</Form.Label>{" "}
            <Form.Control
              placeholder="Enter name"
              type="text"
              {...register("name", {
                required: true,
                validate: value => value?.trim() !== "",
              })}
              isInvalid={isSubmitted && errors?.name}
              isValid={isSubmitted && !errors?.name}
            />
            {errors?.name && <div className="invalid-feedback">Required</div>}
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>Description</Form.Label>
            <textarea
              className={`form-control${
                !isSubmitted
                  ? ""
                  : errors?.description
                  ? " is-invalid"
                  : " is-valid"
              }`}
              placeholder="Enter description"
              rows="5"
              {...register("description", {
                required: true,
                validate: value => value?.trim() !== "",
              })}
            />

            {errors?.description && (
              <div className="invalid-feedback">Required</div>
            )}
          </Form.Group>
          <Button
            disabled={loading}
            variant=""
            type="submit"
            style={{ minHeight: 51 }}
            className="btn btn-primary btn-block tx-16"
          >
            {loading ? (
              <Spinner
                animation="border"
                variant="primary"
                className="spinner-border me-2 text-light"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              "Create"
            )}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
