import React from "react";
import moment from "moment";
import { Col, Row } from "react-bootstrap";

export const GeneralInfoBlock = ({ data }) => {
  return (
    <Row className="my-3 mt-4 tx-12">
      <Col xl={5} lg={5} md={12} xs={12}>
        <div className="mb-2">
          Created at{" "}
          <span className="tx-bold">
            {moment(data?.created_at).format("DD-MM-YYYY HH:mm:ss UTC(Z)")}
          </span>
        </div>
        <div className="mb-2">
          Settled at{" "}
          <span className="tx-bold">
            {moment(data?.updated_at).format("DD-MM-YYYY HH:mm:ss UTC(Z)")}
          </span>
        </div>
        <div className="mb-2">
          Session <span className="tx-bold">{data?.id}</span>
        </div>
        <div className="mb-2">
          IP Address{" "}
          <span className="tx-bold">
            {data?.client_ip}
          </span>
        </div>
      </Col>
      <Col xl={5} lg={5} md={12} xs={12}>
        <div className="mb-2">
          Device User-Agent{" "}
          <span className="tx-bold">
            {data?.user_agent}
          </span>
        </div>
      </Col>
    </Row>
  );
};
