import React, {useEffect, useState} from 'react';
import {Button, Container, Form, Modal} from "react-bootstrap";
import {Slide, toast} from "react-toastify";
import {useMutation} from "react-query";
import {updateCurrencySet, deleteCurrencySet} from "../../../../services";
import {useForm} from "react-hook-form";
import CurrenciesTables from "./CurrenciesTables";

const ConfigModal = ({ configModal, closeModal, onSettled, selectedItem }) => {

    const [savedCurrencies, setSavedCurrencies] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: {errors},
    } = useForm({
        defaultValues: {
            name: "",
            currencies: [],
        },
    });

    const toastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Currency set was updated successfully`
                    : `Currency set update failed`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const deleteToastAlert = type =>
        toast.success(
            <p className="text-white tx-16 mb-0 ">
                {type === "success"
                    ? `Currency set was deleted successfully`
                    : `Couldn't delete currency set`}
            </p>,
            {
                position: toast.POSITION.TOP_RIGHT,
                hideProgressBar: false,
                transition: Slide,
                autoClose: 2000,
                theme: "colored",
                className: type === "success" ? "bg-primary" : "bg-danger",
                type,
            }
        );

    const { mutate: deleteCurrencySetReq } = useMutation(deleteCurrencySet, {
        onSuccess: () => {
            deleteToastAlert("success");
            onSettled();
            setLoading(false);
            closeModal("configModal");
        },
        onError: () => {
            deleteToastAlert("error");
            setLoading(false);
            closeModal("configModal");
        },
    });

    const {mutate} = useMutation(updateCurrencySet, {
        onSuccess: () => {
            toastAlert("success");
            onSettled();
            setLoading(false);
            closeModal("configModal");
        },
        onError: () => {
            toastAlert("error");
            setLoading(false);
            closeModal("configModal");
        },
    });

    const onSubmit = handleSubmit(formData => {
        formData.currencies = savedCurrencies;
        formData.id = selectedItem?.id;
        formData.is_active = selectedItem?.is_active;
        console.log(formData)
        mutate(formData);
        reset();
    });

    useEffect(() => {
        if (selectedItem) {
            setSavedCurrencies(selectedItem.currencies.map(currency => currency.toUpperCase()));
            reset({
                name: selectedItem.name,
                currencies: selectedItem.currencies.map(currency => currency.toUpperCase()).join(","),
            });
        }
    }, [selectedItem, reset, closeModal]);


    return (
        <Modal
            size="lg"
            show={configModal}
            aria-labelledby="example-modal-sizes-title-sm"
            centered>
            <Modal.Header>
                <Modal.Title>Currencies set configuration</Modal.Title>
                <Button
                    variant=""
                    className="btn btn-close ms-auto"
                    onClick={() => {
                        closeModal("configModal")
                        reset({
                            name: selectedItem.name,
                            currencies: selectedItem.currencies.map(currency => currency.toUpperCase()).join(","),
                        });
                        setSavedCurrencies([])
                    }}
                >
                    x
                </Button>
            </Modal.Header>
            <Modal.Body>
                <Container fluid>
                        <Form className={`wd-100p needs-validation${isSubmitted ? "" : ""}`}
                              onSubmit={handleSubmit(onSubmit)}>
                            <Form.Group className="form-group wd-45p mg-l-20">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    placeholder="Enter name"
                                    defaultValue={selectedItem?.name}
                                    type="text"
                                    {...register("name", {
                                        required: true,
                                        validate: value => value?.trim() !== "",
                                    })}
                                    isInvalid={!!errors?.name}
                                    isValid={isSubmitted && !errors?.name}
                                />
                                {errors?.name && (
                                    <Form.Control.Feedback type="invalid">
                                        Name is required
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group className="form-group wd-45p mg-l-20">
                                <Form.Label className="">Values</Form.Label>
                                <Form.Control
                                    readOnly
                                    className={savedCurrencies.length > 0 ? 'text-uppercase' : ''}
                                    value={savedCurrencies}
                                    placeholder="Select currencies from the table"
                                    type="text"
                                    {...register("currencies", {
                                        validate: (value) =>
                                            savedCurrencies.length > 0 ||
                                            "Currencies are required",
                                    })}
                                    isInvalid={!!errors?.currencies}
                                />
                                {errors?.currencies && (
                                    <div className="invalid-feedback">
                                        {errors.currencies.message}
                                    </div>
                                )}
                            </Form.Group>
                            <CurrenciesTables savedCurrencies={savedCurrencies} setSavedCurrencies={setSavedCurrencies}/>
                            <Modal.Footer className="d-flex justify-content-between">
                                <div>
                                    <Button
                                        disabled={loading}
                                        variant="danger"
                                        onClick={() => deleteCurrencySetReq(selectedItem.id)}
                                    >
                                        Delete
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        className='mg-r-10'
                                        disabled={loading}
                                        variant="primary"
                                        type="submit"
                                    >
                                        Save
                                    </Button>
                                    <Button variant="secondary" onClick={() => {
                                        closeModal("createModal");
                                        reset({
                                            name: selectedItem.name,
                                            currencies: selectedItem.currencies.map(currency => currency.toUpperCase()).join(","),
                                        });
                                        setSavedCurrencies([]);
                                    }}>
                                        Cancel
                                    </Button>
                                </div>
                            </Modal.Footer>

                        </Form>
                </Container>
            </Modal.Body>
        </Modal>
    );
};

export default ConfigModal;