export const shouldShowBonusTriggered = gameName =>
  gameName !== "burning-20-wins" &&
  gameName !== "plinko" &&
  !gameName?.includes("smashing-hot") &&
  !gameName?.includes("blazing-clovers");

export const shouldShowRetriggerFreeSpin = gameName =>
  gameName !== "plinko" &&
  !gameName?.includes("smashing-hot") &&
  !gameName?.includes("blazing-clovers") &&
  !gameName?.includes("book-of-dynasty") &&
  !gameName?.includes("book-of-sacred") &&
  !gameName?.includes("majestic-crown") &&
  !gameName?.includes("wild-west-john");

export const shouldShowBonusSpinCounter = gameName =>
  gameName !== "burning-20-wins" &&
  gameName !== "plinko" &&
  !gameName?.includes("smashing-hot") &&
  !gameName?.includes("blazing-clovers") &&
  !gameName?.includes("book-of-dynasty") &&
  !gameName?.includes("book-of-sacred") &&
  !gameName?.includes("majestic-crown") &&
  !gameName?.includes("wild-west-john");
