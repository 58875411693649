import React, {useEffect, useState} from 'react';
import {Form, Col, Row, Card, Breadcrumb} from "react-bootstrap";
import {Table} from '../../../components/Tables'
import ConfigModal from "./components/ConfigModal";
import CreateModal from "./components/CreateModal";
import ConfirmModal from "./components/ConfirmModal";
import {useQuery} from "react-query";
import {getCurrencySet} from "../../../services";
import '../../../components/Tables/_tables.scss'
import './CurrenciesSets.scss'
const CurrencySets = () => {

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [configModal, setConfigModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState();
    const [data, setData] = useState();

    const paginate = {
        page,
        limit,
        setPage,
        setLimit,
    };

    const {isLoading, refetch, isRefetching} = useQuery(
        [
            "currency-set",
            {
                limit,
                page,
            },
        ],
        getCurrencySet,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            onSuccess: data => {
                console.log(data)
                setData(data);
            },
        }
    );

    const columns = [
        {
            className: "text-center",
            key: "all",
            name: "Name",
            render: data => (
                <div className='cursor-pointer' onClick={() => handleItemClick(data.id)}>
                <span
                    className="d-block text-truncate mx-auto"
                    style={{maxWidth: 140}}
                >
          {data.name}
        </span>
                </div>
            ),
        },
        {
            className: "text-center",
            key: "all",
            name: "Values",
            render: (data) => {
                const MAX_CURRENCIES_TO_DISPLAY = 5;

                const visibleCurrencies = data.currencies.slice(0, MAX_CURRENCIES_TO_DISPLAY);
                const remainingCurrencies = data.currencies.length - visibleCurrencies.length;

                const truncatedCurrencies = visibleCurrencies.join(", ");

                return (
                    <div
                        onClick={() => handleItemClick(data.id)}
                        className='d-flex flex-wrap align-items-baseline justify-content-center cursor-pointer'
                        style={{ maxWidth: "100%", overflow: "hidden", textOverflow: "ellipsis" }}
                        title={data.currencies.join(", ")}
                    >
        <span className="d-inline-block text-truncate mg-0 pd-0">
          {truncatedCurrencies.toUpperCase()}
            {remainingCurrencies > 0 && ` ... and ${remainingCurrencies} more`}
        </span>
                    </div>
                );
            }
        },
        {
            className: 'text-center',
            key: 'all',
            name: 'Status',
            render: (data) => {
                return (
                    <div className="d-flex justify-content-around align-items-center">
                        <span
                            className="d-block text-truncate mx-auto"
                            style={{maxWidth: 140}}>
                            {data.is_active === true ? 'on' : 'off'}
                        </span>
                        <Form.Label className="custom-switch ps-0 cursor-pointer">
                            <Form.Control
                                type="checkbox"
                                name={`custom-switch-checkbox`}
                                className="custom-switch-input"
                                checked={data.is_active === true}
                                onChange={() => handleSwitchToggle(data.id)}
                            />
                            <span className="custom-switch-indicator custom-switch-indicator-md"></span>
                        </Form.Label>
                    </div>
                );
            },
        },
    ]

    let showModal = (modal) => {
        switch (modal) {
            case 'configModal':
                setConfigModal(true);
                break;
            case 'confirmModal':
                setConfirmModal(true);
                break;
            case 'createModal':
                setCreateModal(true);
                break;
        }
    }

    let closeModal = (modal) => {
        switch (modal) {
            case 'configModal':
                setConfigModal(false);
                break;
            case 'confirmModal':
                setConfirmModal(false);
                break;
            case 'createModal':
                setCreateModal(false);
                break;
        }
    }

    const handleItemClick = (propertyValue) => {
        const selectedItem = data?.items.find(item => item.id === propertyValue);
        console.log(selectedItem)
        setSelectedItem(selectedItem);
        showModal('configModal');
    };

    const handleSwitchToggle = (propertyValue) => {
        const selectedItem = data?.items.find(item => item.id === propertyValue);
        console.log(selectedItem)
        setSelectedItem(selectedItem);
        showModal('confirmModal');
    }

    useEffect(() => {
        refetch();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="wager-sets-page">

            {/* <!-- breadcrumb --> */}
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
        <span className="main-content-title mg-b-0 mg-b-lg-1">
          Currencies sets
        </span>
                </div>
                <div className="justify-content-center mg-t-55 mg-r-100">
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item tx-15" onClick={() => showModal("createModal")}>
                            <span>Create</span> <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            {/* <!-- /breadcrumb --> */}

            {/* <!-- row --> */}
            <Row className='overflow-hidden currencies-sets'>
                <Col  xl={11} lg={10} md={10} xs={9}>
                    <Card className="mg-l-35">
                        <Card.Body className="pt-4 example1-table">
                            <Table
                                loading={isLoading || isRefetching}
                                data={data ? data.items : []}
                                columns={columns}
                                paginate={paginate}
                                total={data ? data?.total : 0}
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <ConfigModal onSettled={refetch} selectedItem={selectedItem} configModal={configModal} closeModal={() =>closeModal('configModal')}/>
            <CreateModal onSettled={refetch} createModal={createModal} closeModal={() =>closeModal('createModal')}/>
            <ConfirmModal onSettled={refetch} selectedItem={selectedItem} confirmModal={confirmModal} closeModal={() =>closeModal('confirmModal')}/>
        </div>
    );
};

export default CurrencySets;