import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getCurrenciesData } from '../../../../services';
import { Card, Col, Row, Form, Accordion } from 'react-bootstrap';
const CurrenciesTable = ({ savedCurrencies, setSavedCurrencies }) => {
    const [commonCurrencies, setCommonCurrencies] = useState([]);
    const [otherCurrencies, setOtherCurrencies] = useState([])
    const [cryptoCurrencies, setCryptoCurrencies] = useState([])

    const handleCheckboxChange = (currency) => {
        const uppercaseCurrency = currency.toUpperCase();
        if (savedCurrencies.includes(uppercaseCurrency)) {
            setSavedCurrencies((prevCurrencies) =>
                prevCurrencies.filter((c) => c !== uppercaseCurrency)
            );
        } else {
            setSavedCurrencies((prevCurrencies) => [...prevCurrencies, uppercaseCurrency]);
        }
    };

    const { data, isLoading } = useQuery(
        ['currencies'],
        getCurrenciesData,
        {
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                setCommonCurrencies(data.slice(0, 70));
                setOtherCurrencies(data.slice(71,200))
                setCryptoCurrencies(data.slice(201))
            },
        }
    );

    const renderCurrencyRow = (currency) => {
        const isChecked =
            savedCurrencies.includes(currency.toUpperCase()) || savedCurrencies.includes(currency.toLowerCase());

        return (
            <td key={currency} className="align-middle">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Form.Label className="custom-control custom-checkbox cursor-pointer">
                        <Form.Control
                            type="checkbox"
                            className="custom-control-input"
                            name="example-checkbox1"
                            checked={isChecked}
                            onChange={() => handleCheckboxChange(currency)}
                        />
                        <span className="custom-control-label tx-13">{currency.toUpperCase()}</span>
                    </Form.Label>
                </div>
            </td>
        );
    };

    const renderCurrencyTable = (title, currencies) => {
        const rows = [];
        const columnsPerRow = 4;

        for (let i = 0; i < currencies?.length; i += columnsPerRow) {
            const row = currencies.slice(i, i + columnsPerRow);
            const rowElements = row.map((currency) => renderCurrencyRow(currency));
            rows.push(<tr key={i}>{rowElements}</tr>);
        }

        return (
            <Accordion className='accordion'>
                <Accordion.Header>{title}</Accordion.Header>
                <Accordion.Body>
                    <div className="table-responsive">
                        <Row className="overflow-hidden">
                            <Col>
                                <Card>
                                    <Card.Body>
                                        <table className="table table-bordered currencies-table mb-0 text-md-nowrap">
                                            <tbody>{rows}</tbody>
                                        </table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Accordion.Body>
            </Accordion>
        );
    };

    return (
        <div>
            {renderCurrencyTable('Common Currencies', commonCurrencies)}
            {renderCurrencyTable('Other Currencies', otherCurrencies)}
            {renderCurrencyTable('Crypto Currencies', cryptoCurrencies)}
        </div>
    );
};

export default CurrenciesTable;
