import React, {useEffect} from "react";
import { Card } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { toast, Slide } from "react-toastify";
import {
  getPermissons,
  addRolePermission,
  deleteRolePermission,
} from "../../services";
import { Table } from "../../components/Tables";
import { Select } from "antd";
import { getRoles } from "../../services";
import { useLocation } from "react-router-dom";
import { useProfile } from "../../contexts";

const Permissons = () => {
  const client = useQueryClient();
  const location = useLocation();
  const search = location.search.substring(1);
  const params = search?.split("&")?.map(item => item?.split("=")[1]);
  const { profileData } = useProfile();
  const [data, setData] = React.useState();
  const [limit, setLimit] = React.useState(50);
  const [page, setPage] = React.useState(1);
  const [selectedIDs, setSelectedIDs] = React.useState([]);
  const [role, setRole] = React.useState(null);
  const {
    data: permissionsData,
    isLoading,
    isRefetching,
  } = useQuery(
    [
      "permissions",
      {
        limit,
        offset: (page - 1) * limit,
      },
    ],
    getPermissons,
    {
      refetchOnWindowFocus: false,
      onSuccess: ({ data }) => {
        setData(data);
      },
    }
  );

  const { data: roles } = useQuery(
    ["roles", { userType: profileData?.roles?.[0]?.type }],
    getRoles,
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        if (params[1] && selectedIDs?.length === 0) {
          const urlIDs = data
            ?.find(item => item?.id === params[1])
            ?.permissions?.map(item => item?.id);
          setSelectedIDs(urlIDs);
        }
      },
    }
  );

  const toastAlert = type =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success" ? `Updated successfully` : `Couldn't update`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const addPermissions = useMutation(addRolePermission, {
    onSuccess: (data, vairables) => {
      vairables?.shouldToast && toastAlert("success");
      client.invalidateQueries("roles");
      window.location.reload();
    },
    onError: () => {
      toastAlert("error");
    },
  });

  const { mutate: deletePermissions } = useMutation(deleteRolePermission, {
    onSuccess: () => {
      toastAlert("success");
      client.invalidateQueries("roles");
      window.location.reload();
    },
    onError: () => {
      toastAlert("error");
    },
  });

  const onSubmit = async () => {
    let rolePermissions = role?.permissions
      ? role?.permissions?.map(item => item?.id)
      : roles
          ?.find(item => item?.id === role?.value)
          ?.permissions?.map(item => item?.id);

    const deletedPermissions = rolePermissions?.filter(
      id => !selectedIDs?.includes(id)
    );

    const addedPermissions = selectedIDs?.filter(
      id => !rolePermissions.includes(id)
    );

    if (addedPermissions?.length > 0) {
      await addPermissions.mutate({
        id: role?.value,
        permissions: addedPermissions,
        shouldToast: deletedPermissions?.length === 0,
      });
    }

    if (deletedPermissions?.length > 0) {
      await deletePermissions({
        id: role?.value,
        permissions: deletedPermissions,
      });
    }
  };

  const columns = [
    {
      className: "text-center",
      key: "name",
      name: "Name",
    },
    {
      className: "text-center",
      key: "description",
      name: "Description",
    },
    {
      className: "text-center",
      key: "action",
      name: "Action",
      width: 200,
    },
    {
      className: "text-center",
      key: "endpoint",
      name: "End point",
      width: 350,
    },

    {
      className: "text-center",
      key: "subject",
      name: "Subject",
      width: 270,
    },
  ];

  const paginate = {
    page,
    limit,
    setPage,
    setLimit,
  };

  const onSelect = {
    selectedIDs,
    onSelect: ({ id }) => {
      if (selectedIDs?.includes(id)) {
        setSelectedIDs(selectedIDs?.filter(item => item !== id));
      } else {
        setSelectedIDs(selectedIDs?.concat([id]));
      }
    },
    onSelectAll: () => {
      if (
        JSON.stringify(selectedIDs) ===
        JSON.stringify(data?.map(item => item?.id))
      ) {
        setSelectedIDs([]);
      } else {
        setSelectedIDs(data?.map(item => item?.id));
      }
    },
  };

  return (
    <>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Permissions
          </span>
        </div>
      </div>
      <form className="w-100" id="filters" onSubmit={e => e.preventDefault()}>
        <div className="row">
          <div className="form-group col-3">
            <label className="form-label">Role</label>
            <div className="d-flex align-items-center">
              <Select
                className="form-control"
                showSearch
                placeholder="Select Role..."
                optionFilterProp="children"
                value={role || null}
                onChange={(role, roleItem) => {
                  setRole(roleItem);
                  const permissions = roles
                    ?.find(item => item?.id === role)
                    ?.permissions?.map(i => i?.id);
                  setSelectedIDs(permissions);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={
                  roles?.map(item => ({
                    value: item?.id,
                    label: item?.name?.toUpperCase(),
                    permissions: item?.permissions,
                  })) || []
                }
              />
              <button
                className="btn btn-info ms-2"
                disabled={!role}
                onClick={onSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
      <Card>
        <Card.Body className="pt-4 example1-table">
          <Table
            loading={isLoading || isRefetching}
            data={data ? data : []}
            columns={columns}
            onSelect={onSelect}
            paginate={paginate}
            total={permissionsData ? permissionsData?.total : 0}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Permissons;
