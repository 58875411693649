import httpClient from "./httpClient";

export const getCurrencySet = async ({ queryKey }) => {
    const params = new URLSearchParams(queryKey[1]);
    const { data } = await httpClient.get(`/currency_set?${params.toString()}`);
    if (data) {
        return data.data;
    }
};

export const createCurrencySet = async formData => {
    const { data } = await httpClient.post("/currency_set", { ...formData });
    if (data) {
        return data.data;
    }
};

export const updateCurrencySet = async formData => {
    const { data } = await httpClient.post(`/currency_set/${formData?.id}`, { ...formData });
    if (data) {
        return data.data;
    }
};

export const deleteCurrencySet = async id => {
    const { data } = await httpClient.delete(`/currency_set/${id}`);
    if (data) {
        return data.data;
    }
};