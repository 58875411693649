import React from "react";
import {Accordion, Card, Col, Row, Spinner} from "react-bootstrap";
import { useQuery } from "react-query";
import { useProfile } from "../../../contexts";
import { getConfig } from "../../../services";
import { EditCurrency } from "./components";

const Currencies = () => {
  const { organizationId } = useProfile();
  const [multipliers, setMultipliers] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);

  const { data, isLoading } = useQuery(
    ["get-config", { id: organizationId }],
    getConfig,
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
          console.log(data)
        setMultipliers(
          Object.keys(data?.multipliers)?.map(currency => ({
            title: currency,
            multiplier: data?.multipliers?.[currency],
          }))
        );
      },
    }
  );

  return (
    <>
      {showModal && selectedData && (
        <EditCurrency
          setShowModal={setShowModal}
          data={selectedData}
          setSelectedData={setSelectedData}
        />
      )}
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Currencies Multipliers
          </span>
        </div>
      </div>
      {multipliers.length === 0 && !isLoading && (
        <div
          className="d-flex align-items-center justify-content-center text-muted tx-18"
          style={{ height: "50vh" }}
        >
          {isLoading ? <Spinner variant="primary" /> : " No data do display"}
        </div>
      )}
        {multipliers.length > 0 && !isLoading && (
        <div className="mg-t-30">
            <Accordion defaultActiveKey="main-currencies">
                <Accordion.Item eventKey={"main-currencies"}>
                <Accordion.Header>Main currencies</Accordion.Header>
                <Accordion.Body className="custom-multipliers-accordion">
                    <div className="multipliers-container">
                        {multipliers.length > 0 && (
                            <div className="row wd-95p mg-l-10">
                                {multipliers.map((item, key) => (
                                    <div key={key} className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                        <Card className="custom-multiplier-card">
                                            <Card.Body className="custom-multiplier-card-body py-2">
                                                <Row>
                                                    <Col
                                                        xl={4}
                                                        lg={4}
                                                        md={4}
                                                        xs={4}
                                                        className="bd-e py-1 tx-bold tx-16 d-flex align-items-center"
                                                    >
                                                        {item?.title?.toUpperCase()}
                                                    </Col>
                                                    <Col xl={4} lg={4} md={4} xs={4} className="bd-e py-1">
                                                        <div className="d-flex flex-column">
                                                            <span className="d-block text-muted">Rate</span>
                                                            <span className="d-block tx-bold tx-15">
                            {item?.multiplier?.toLocaleString()}
                          </span>
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        xl={4}
                                                        lg={4}
                                                        md={4}
                                                        xs={4}
                                                        className="py-1 d-flex align-items-center justify-content-end"
                                                    >
                                                        <button
                                                            className="btn btn-outline-info d-flex align-items-center justify-content-center p-1 px-2"
                                                            onClick={() => {
                                                                setSelectedData({
                                                                    ...item,
                                                                    provider_id: organizationId,
                                                                    integrator_id: data?.integration_id,
                                                                });
                                                                setShowModal(true);
                                                            }}
                                                        >
                                                            Edit <i className="fe fe-edit-2 ms-1" />
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={"other-currencies"}>
                    <Accordion.Header>Other currencies</Accordion.Header>
                    <Accordion.Body className="custom-multipliers-accordion">
                        <div className="multipliers-container">
                            {multipliers.length > 0 && (
                                <div className="row wd-95p mg-l-10">
                                    {multipliers.map((item, key) => (
                                        <div key={key} className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                            <Card className="custom-multiplier-card">
                                                <Card.Body className="custom-multiplier-card-body py-2">
                                                    <Row>
                                                        <Col
                                                            xl={4}
                                                            lg={4}
                                                            md={4}
                                                            xs={4}
                                                            className="bd-e py-1 tx-bold tx-16 d-flex align-items-center"
                                                        >
                                                            {item?.title?.toUpperCase()}
                                                        </Col>
                                                        <Col xl={4} lg={4} md={4} xs={4} className="bd-e py-1">
                                                            <div className="d-flex flex-column">
                                                                <span className="d-block text-muted">Rate</span>
                                                                <span className="d-block tx-bold tx-15">
                            {item?.multiplier?.toLocaleString()}
                          </span>
                                                            </div>
                                                        </Col>
                                                        <Col
                                                            xl={4}
                                                            lg={4}
                                                            md={4}
                                                            xs={4}
                                                            className="py-1 d-flex align-items-center justify-content-end"
                                                        >
                                                            <button
                                                                className="btn btn-outline-info d-flex align-items-center justify-content-center p-1 px-2"
                                                                onClick={() => {
                                                                    setSelectedData({
                                                                        ...item,
                                                                        provider_id: organizationId,
                                                                        integrator_id: data?.integration_id,
                                                                    });
                                                                    setShowModal(true);
                                                                }}
                                                            >
                                                                Edit <i className="fe fe-edit-2 ms-1" />
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey={"crypto-currencies"}>
                    <Accordion.Header>Crypto currencies</Accordion.Header>
                    <Accordion.Body className="custom-multipliers-accordion">
                        <div className="multipliers-container">
                            {multipliers.length > 0 && (
                                <div className="row wd-95p mg-l-10">
                                    {multipliers.map((item, key) => (
                                        <div key={key} className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                                            <Card className="custom-multiplier-card">
                                                <Card.Body className="custom-multiplier-card-body py-2">
                                                    <Row>
                                                        <Col
                                                            xl={4}
                                                            lg={4}
                                                            md={4}
                                                            xs={4}
                                                            className="bd-e py-1 tx-bold tx-16 d-flex align-items-center"
                                                        >
                                                            {item?.title?.toUpperCase()}
                                                        </Col>
                                                        <Col xl={4} lg={4} md={4} xs={4} className="bd-e py-1">
                                                            <div className="d-flex flex-column">
                                                                <span className="d-block text-muted">Rate</span>
                                                                <span className="d-block tx-bold tx-15">
                            {item?.multiplier?.toLocaleString()}
                          </span>
                                                            </div>
                                                        </Col>
                                                        <Col
                                                            xl={4}
                                                            lg={4}
                                                            md={4}
                                                            xs={4}
                                                            className="py-1 d-flex align-items-center justify-content-end"
                                                        >
                                                            <button
                                                                className="btn btn-outline-info d-flex align-items-center justify-content-center p-1 px-2"
                                                                onClick={() => {
                                                                    setSelectedData({
                                                                        ...item,
                                                                        provider_id: organizationId,
                                                                        integrator_id: data?.integration_id,
                                                                    });
                                                                    setShowModal(true);
                                                                }}
                                                            >
                                                                Edit <i className="fe fe-edit-2 ms-1" />
                                                            </button>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
        )}
    </>
  );
};

export default Currencies;
