import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import { useProfile } from "../../contexts";
import { getRoles, deleteRole } from "../../services";
import { RolesModal } from "./components";
import { toast, Slide } from "react-toastify";
import { DeleteModal } from "./components/deleteModal";
import { Table } from "../../components/Tables";

const Roles = () => {
  const [data, setData] = React.useState();
  const [limit] = React.useState(100);
  const [showModal, setShowModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [page] = React.useState(1);
  const { organizationId } = useProfile();
  const { isLoading, isRefetching, refetch } = useQuery(
    [
      "roles-request",
      {
        limit,
        offset: (page - 1) * limit,
      },
    ],
    getRoles,
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: true,
      onSuccess: data => {
        setData(data);
      },
    }
  );

  const { mutate: deleteRoleReq } = useMutation(deleteRole, {
    onSuccess: () => {
      setShowDeleteModal(false);
      toastAlert("success");
      refetch();
      selectedData && setSelectedData(null);
    },
    onError: () => {
      toastAlert("error");
      setShowDeleteModal(false);
      selectedData && setSelectedData(null);
    },
  });

  const toastAlert = type =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Role deleted successfully`
          : `Couldn't delete role`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-info" : "bg-danger",
        type,
      }
    );

  const columns = [
    {
      className: "text-center",
      key: "all",
      name: "Name",
      render: data =>
        data?.type === "admin" || data?.type === "root" ? (
          <span className="text-primary">{data?.name}</span>
        ) : (
          <Link
            to={`/users/permissions/?${new URLSearchParams({
              label: data?.name?.toUpperCase(),
              value: data?.id,
            })}`}
            className="text-primary text-underline cursor-pointer d-block text-truncate mx-auto"
            style={{
              textDecoration: "underline",
              maxWidth: 140,
            }}
          >
            {data?.name}
          </Link>
        ),
    },

    {
      className: "text-center",
      key: "description",
      name: "Description",
    },
    {
      className: "text-center",
      key: "permissions",
      name: "Permissions",
      render: data => {
        return (
          <div className="d-flex align-items-center flex-wrap">
            {data
              ? data?.map((role, key) => (
                  <span key={key} className="badge bg-primary me-1 my-2 tx-12">
                    {role?.name}
                  </span>
                ))
              : "-"}
          </div>
        );
      },
    },
    {
      className: "text-center",
      key: "slug",
      name: "Slug",
    },
    {
      className: "text-center",
      key: "all",
      name: "Actions",
      width: 60,
      render: data =>
        data?.type === "admin" ? (
          ""
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <button
              className="btn btn-outline-danger d-flex align-items-center justify-content-center p-1"
              onClick={() => {
                setSelectedData(data);
                setShowDeleteModal(true);
              }}
            >
              <i className="fe fe-trash" />
            </button>
          </div>
        ),
    },
  ];

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [organizationId]);

  return (
    <>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">Roles</span>
        </div>
        <div className="right-content">
          <button
            className="btn btn-info t-16 py-2 d-flex align-items-center justify-content-center"
            onClick={() => setShowModal(true)}
          >
            <i className="fe fe-plus"></i>
          </button>
        </div>
      </div>
      {showModal && (
        <RolesModal setShowModal={setShowModal} onSettled={refetch} />
      )}
      {showDeleteModal && (
        <DeleteModal
          title="Role"
          onAccept={() => deleteRoleReq(selectedData?.id)}
          setShowModal={() => {
            setShowDeleteModal();
            setSelectedData(null);
          }}
          additionalInfo={`Role: ${selectedData?.name}`}
        />
      )}
      <Card>
        <Card.Body className="pt-4 example1-table">
          <Table
            loading={isLoading || isRefetching}
            data={data ? data : []}
            columns={columns}
            total={data ? data?.length : 0}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default Roles;
