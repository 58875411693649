import httpClient from "./httpClient";

export const getRevenueReports = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(
    `/reports/financial?${params.toString()}`
  );
  if (data) {
    return data.data;
  }
};

export const getReportsByGame = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(
      `/reports/aggregated/by_game?${params.toString()}`
  );
  if (data) {
    return data.data;
  }
};

export const getGameReport = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[2]);
  const { data } = await httpClient.get(
      `/reports/aggregated/by_country/${queryKey[1]}?${params.toString()}`
  );
  if (data) {
    return data.data;
  }
};

export const getCountryReport = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[2]);
  const { data } = await httpClient.get(
      `/reports/aggregated/by_game/${queryKey[1]}?${params.toString()}`
  );
  if (data) {
    return data.data;
  }
};

export const getReportsByGameFile = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(
      `/reports/aggregated/by_game/xlsx?${params.toString()}`
  );
  if (data) {
    return data.data;
  }
}

export const getReportsByCountry = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(
      `/reports/aggregated/by_country?${params.toString()}`
  );
  if (data) {
    return data.data;
  }
};

export const getReportsByCountryFile = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(
      `/reports/aggregated/by_country/xlsx?${params.toString()}`
  );
  if (data) {
    return data.data;
  }
}

export const getCurrencies = async () => {
  const { data } = await httpClient.get("/reports/currencies");
  if (data) {
    return data.data?.filter(item => item);
  }
};

export const getSpinsList = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(`/reports/spins?${params.toString()}`);
  if (data) {
    return data.data;
  }
};

export const getSpin = ({ queryKey }) => {
  const { id } = queryKey[1];
  const params = new URLSearchParams(queryKey[2]);
  return httpClient
      .get(`/reports/spins/${id}?${params.toString()}`)
      .then((response) => {
        const { data } = response;
        if (data) {
          return data.data;
        }
      });
};

export const getUserDetails = async ({ queryKey }) => {
  const { id } = queryKey[1];
  const params = new URLSearchParams(queryKey[2]);
  const { data } = await httpClient.get(`/reports/users/${id}?${params.toString()}`)
  if (data) {
    return data.data;
  }
}

export const getRevenueCsv = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  let fileName = `${queryKey[0]} ${queryKey[1]["currency"]?.toUpperCase()}`;
  const {data} = await httpClient.get(`/reports/financial/xlsx?${params.toString()}`, fileName);
  if (data) {
    return data.data;
  }
}

export const getSpinsFile = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const {data} = await httpClient.get(`/reports/spins/xlsx?${params.toString()}`);
  if (data) {
    return data.data;
  }
};
