export const stonesOfMagicPaylines = [
  [5, 6, 7, 8, 9],
  [0, 1, 2, 3, 4],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
  [0, 1, 7, 3, 4],
  [10, 11, 7, 13, 14],
  [5, 11, 12, 13, 9],
  [5, 1, 2, 3, 9],
  [5, 1, 7, 3, 9],
  [5, 11, 7, 13, 9],
  [0, 6, 2, 8, 4],
  [10, 6, 12, 8, 14],
  [5, 6, 2, 8, 9],
  [5, 6, 12, 8, 9],
  [0, 6, 7, 8, 4],
  [10, 6, 7, 8, 14],
  [0, 6, 12, 13, 14],
  [10, 6, 2, 3, 4],
  [0, 11, 2, 13, 4],
];

export const smashingHotPaylines = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
];

export const smashingHot20Paylines = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
  [0, 1, 7, 13, 14],
  [10, 11, 7, 3, 4],
  [5, 1, 7, 13, 9],
  [5, 11, 7, 3, 9],
  [5, 1, 2, 8, 4],
  [5, 11, 12, 8, 14],
  [0, 6, 2, 3, 9],
  [10, 6, 12, 13, 9],
  [0, 11, 2, 13, 4],
  [10, 1, 12, 3, 14],
  [5, 1, 12, 3, 9],
  [5, 11, 2, 13, 9],
  [0, 6, 7, 8, 4],
  [10, 6, 7, 8, 14],
  [0, 11, 12, 13, 4],
];

export const burningWinPaylines = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
];

export const preciousBugsPaylines = [
  [5, 6, 7, 8, 9],
  [0, 1, 2, 3, 4],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
  [5, 1, 2, 3, 9],
  [5, 11, 12, 13, 9],
  [10, 11, 7, 13, 14],
  [0, 1, 7, 3, 4],
  [5, 1, 7, 3, 9],
];

export const bookOfJonesPaylines = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
  [5, 1, 2, 3, 9],
  [5, 11, 12, 13, 9],
  [0, 1, 7, 13, 14],
  [10, 11, 7, 3, 4],
  [5, 11, 7, 3, 9],
];

export const bookOfSacredPaylines = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
  [0, 1, 7, 13, 14],
  [10, 11, 7, 3, 4],
  [5, 11, 12, 13, 9],
  [5, 1, 2, 3, 9],
  [10, 6, 7, 8, 4],
];

export const majesticCrownPaylines = [
  [0, 1, 2, 3, 4],
  [5, 6, 7, 8, 9],
  [10, 11, 12, 13, 14],
  [0, 6, 12, 8, 4],
  [10, 6, 2, 8, 14],
  [5, 11, 12, 13, 9],
  [5, 1, 2, 3, 9],
  [10, 11, 7, 3, 4],
  [0, 1, 7, 13, 14],
  [10, 6, 7, 8, 4],
];

export const wildWestJohnPaylines = [
  [0, 1, 2],
  [0, 1, 5],
  [0, 1, 8],
  [0, 4, 2],
  [0, 4, 5],
  [0, 4, 8],
  [0, 7, 2],
  [0, 7, 5],
  [0, 7, 8],
  [3, 1, 2],
  [3, 1, 5],
  [3, 1, 8],
  [3, 4, 2],
  [3, 4, 5],
  [3, 4, 8],
  [3, 7, 2],
  [3, 7, 5],
  [3, 7, 8],
  [6, 1, 2],
  [6, 1, 5],
  [6, 1, 8],
  [6, 4, 2],
  [6, 4, 5],
  [6, 4, 8],
  [6, 7, 2],
  [6, 7, 5],
  [6, 7, 8],
];
