import React from "react";
import { GameStateCollapse } from "./gameStateCollapse";
import "../SpinDetail/components/index.scss";

export const GameStateCard = ({ data }) => {
  return (
    <div className="py-3 spin-detail pe-0">
      <GameStateCollapse data={data} />
    </div>
  );
};
