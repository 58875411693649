import httpClient from "./httpClient";

export const authLogIn = data => httpClient.post("/auth/login", { ...data });
export const authChangeOrganization = data =>
  httpClient.post("/auth/organization", { ...data });

export const getUserProfile = async () => {
  const { data } = await httpClient.get("auth/session");
  if (data) {
    return data.data;
  }
};

export const getConfig = async ({ queryKey }) => {
  const { id } = queryKey[1];
  const { data } = await httpClient.get(`/currency/config/${id}`);
  if (data) {
    return data.data?.[0];
  }
};

export const updateCurrency = async formData => {
  const { data } = await httpClient.put("/currency/multiplier", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};

export const getQrCode = async formData => {
  const { data } = await httpClient.post("/auth/otp/generate", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};
export const updatePassword = async formData => {
  const { data } = await httpClient.post("/auth/password/change", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};
export const enableTwoAuth = async formData => {
  const { data } = await httpClient.post("/auth/otp/enable", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};
export const disableTwoAuth = async formData => {
  const { data } = await httpClient.post("/auth/otp/disable", {
    ...formData,
  });
  if (data) {
    return data.data;
  }
};
