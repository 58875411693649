import React from "react";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { Table } from "../../../components/Tables";
import { getSessionsList, getRevenueReports } from "../../../services";
import { Select } from "antd";
import { useQuery } from "react-query";
import { useCurrency } from "../../../contexts/currencyContext";
import { convertToCurrency } from "../../../utils";
import getSymbolFromCurrency from "currency-symbol-map";
import { useProfile } from "../../../contexts";
import {useDemo} from "../../../contexts/demoContext";

export const GamePerformanceBlock = ({ games, hosts, type = null }) => {
  const params = {
    starting_from: "",
    ending_at: "",
  };
  const [game, setGame] = React.useState("");
  const [data, setData] = React.useState();
  const [host, setHost] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);

  const { currency } = useCurrency();
  const { organizationId } = useProfile();
  const { is_demo } = useDemo();

  const {
    data: revenueData,
    refetch: refetchRevenueData,
    isLoading: revenueDataLoading,
  } = useQuery(
    [
      "revenue-reports",
      {
        is_demo,
        currency,
        game,
        host,
        ...params,
      },
    ],
    getRevenueReports,
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      refetchOnMount: false,
      enabled: false,
    }
  );

  const { refetch, isLoading, isRefetching } = useQuery(
    [
      `session-spins${type ? "-game" : ""}`,
      {
        currency,
        limit,
        page,
        host: host || "",
        game: game || "",
        ...params,
        order: "created_at desc",

      },
    ],
    getSessionsList,
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: false,
      onSuccess: data => {
        setData(data);
      },
    }
  );

  const columns = [
    {
      className: "text-center",
      key: "all",
      name: "Game",
      render: data => data?.game,
    },
    {
      className: "text-center",
      key: "wager",
      name: "Wager",
      render: data => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "award",
      name: "Awards",
      render: data => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "revenue",
      name: "Revenue",
      render: data => convertToCurrency(data, currency),
    },
    {
      className: "text-center",
      key: "margin",
      name: "Margin",
      render: data => data || " - ",
    },
  ];

  const paginate = {
    page,
    limit,
    setPage,
    setLimit,
  };

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [game, page, limit, organizationId, host, is_demo]);

  return (
    <Card>
      <Card.Body className="px-3 py-3 example1-table">
        <h5 className="tx-18">Game Performance</h5>
        <form className="w-100" id="filters" onSubmit={e => e.preventDefault()}>
          <div className="row mb-3">
            <div className="form-group mb-0 col-2">
              <label className="form-label">GAME</label>
              <Select
                className="form-control"
                showSearch
                placeholder="Select Game..."
                allowClear
                optionFilterProp="children"
                value={game || null}
                onChange={game => setGame(game)}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={
                  games?.map(item => ({
                    value: item?.name,
                    label: item?.name?.toUpperCase(),
                  })) || []
                }
              />
            </div>
            <div className="form-group mb-0 col-2">
              <label className="form-label">Host</label>
              <Select
                className="form-control"
                showSearch
                placeholder="Select Host..."
                allowClear
                optionFilterProp="children"
                value={host || null}
                onChange={host => setHost(host)}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={
                  hosts?.map(item => ({
                    value: item,
                    label: item,
                  })) || []
                }
              />
            </div>
          </div>
        </form>
        <Row>
          <Col xl={8} lg={8} md={12} xs={12}>
            <Table
              loading={isLoading || isRefetching}
              data={data ? data.items : []}
              columns={columns}
              sizes={[10, 25, 50]}
              paginate={paginate}
              total={data ? data?.total : 0}
            />
          </Col>
          <Col
            className="d-flex flex-column align-items-center"
            xl={4}
            lg={4}
            md={12}
            xs={12}
          >
            <div
              className="w-100 d-flex flex-column align-items-center justify-content-center bd bd-info radius-10 mb-3"
              style={{ height: 200, backgroundColor: "rgb(13 202 240 / 10%)" }}
            >
              <span className="d-block tx-14">Total Wager</span>
              <span className="d-block tx-18">
                {revenueDataLoading ? (
                  <Spinner />
                ) : (
                  `${
                    getSymbolFromCurrency(currency?.toUpperCase()) ||
                    currency?.toUpperCase()
                  }${revenueData?.wager || "0.00"}`
                )}
              </span>
            </div>
            <div
              className="w-100 d-flex flex-column align-items-center justify-content-center bd bd-secondary radius-10 mb-3"
              style={{
                height: 200,
                backgroundColor: "rgb(247 79 117 / 17%)",
              }}
            >
              <span className="d-block tx-14">Total Awards</span>
              <span className="d-block tx-18">
                {revenueDataLoading ? (
                  <Spinner />
                ) : (
                  `${
                    getSymbolFromCurrency(currency?.toUpperCase()) ||
                    currency?.toUpperCase()
                  }${revenueData?.award || "0.00"}`
                )}
              </span>
            </div>
            <div
              className="w-100 d-flex flex-column align-items-center justify-content-center bd bd-primary radius-10"
              style={{
                height: 200,
                backgroundColor: "rgba(56, 202, 179, 0.06)",
              }}
            >
              <span className="d-block tx-14">Revenue</span>
              <span className="d-block tx-18">
                {revenueDataLoading ? (
                  <Spinner />
                ) : (
                  `${
                    getSymbolFromCurrency(currency?.toUpperCase()) ||
                    currency?.toUpperCase()
                  }${revenueData?.revenue || "0.00"}`
                )}
              </span>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
