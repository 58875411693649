import {
  stonesOfMagicPaylines,
  bookOfJonesPaylines,
  burningWinPaylines,
  smashingHot20Paylines,
  smashingHotPaylines,
  bookOfSacredPaylines,
  majesticCrownPaylines,
  wildWestJohnPaylines,
  preciousBugsPaylines,
} from "./payLinesArrays";

export const stages = (gameName, data, isAllowed) => {
  if (!isAllowed) return [];
  let gameStages = [];

  const sameStructuredGames = [
    "smashing-hot",
    "smashing-hot-20",
    "smashing-hot-94",
    "smashing-hot-96",
    "smashing-hot-20-94",
    "smashing-hot-20-96",
    "burning-20-wins",
    "blazing-clovers",
    "book-of-dynasty",
    "book-of-sacred",
    "wild-west-john",
    "majestic-crown",
  ];

  if (gameName === "stonesofmagic" || gameName === "secrettotems" ) {
    gameStages = data?.spin?.spins?.[0]?.stages;
  }

  if (gameName === "asgardparty" || gameName === "ego-draconis") {
    gameStages = data?.spin?.spins?.[0]?.stages;
  }

  if (gameName === "preciousbugs" || gameName === "cyber-town") {
    gameStages = [
      {
        payouts: {
          values: data?.spin?.payouts?.spin_payouts?.map((value) => ({
            amount: value.amount,
            payline: value.payline,
            count: value.count,
          })),
        },
        reel_window: data?.spin?.window,
        bonus_game:
          data?.spin?.bonus?.spins_results?.length > 0
            ? {
                spins: data?.spin?.bonus?.spins_results?.map(item => ({
                  stages: [
                    {
                      reel_window: item?.window,
                      payouts: { values: item?.payouts?.spin_payouts },
                      stops: item?.reels?.stops,
                    },
                  ],
                })),
              }
            : null,
      },
    ];
  }

  if (gameName === "lucky-pint" || gameName === "undead-riches" || gameName === "retro-royale") {
    gameStages = [
      {
        payouts: {
          values: data?.spin?.pay_lines_to_show?.map((value) => ({
            amount: value.award,
            payline: value.index,
            count: value.indexes.length,
            indexes: value.indexes,
          })),
        },
        reel_window: data?.spin?.window,
        bonus_game:
            data?.spin?.bonus?.spins?.length > 0
                ? {
                  spins: data?.spin?.bonus?.spins?.map((item) => ({
                    stages: [
                      {
                        reel_window: item?.window,
                        payouts:
                            item.pay_lines_to_show?.length > 0
                                ? {
                                  scatter_values: item.extra_scatter_pay_lines,
                                  values: item.pay_lines_to_show?.map((value) => ({
                                    amount: value.award,
                                    payline: value.index,
                                    count: value.indexes.length,
                                    indexes: value.indexes,
                                  })),
                                }
                                : {
                                  values: null,
                                  scatter_values: item.extra_scatter_pay_lines,
                                },
                      },
                    ],
                  })),
                }
                : null,
      },
    ];

  }

  if (gameName === "book-of-jones-96" || gameName === "book-of-jones-94") {
    gameStages = [
      {
        payouts: {
          values: data?.spin?.payouts?.spin_payouts?.map((value) => ({
            amount: value.amount,
            payline: value.payline,
            count: value.count,
          })),
        },
        reel_window: data?.spin?.window,
        bonus_game: data?.spin?.bonus_spins?.length > 0
          ? {
              spins: data?.spin?.bonus_spins?.map(item => ({
                stages: [
                  {
                    reel_window: item?.window,
                    reels: {
                      scatter_position: item?.reels?.expanding_symbol_position,
                    },
                    payouts:
                        item.payouts_info?.spin_payouts?.length > 0
                            ? {
                              scatter_values: item.payouts_info?.expanded_symbol_payouts || null,
                              values: item.payouts_info?.spin_payouts?.map((value) => ({
                                amount: value.amount,
                                payline: value.payline,
                                count: value.count,
                              })),
                            }
                            : {
                              values: null,
                              scatter_values: item.payouts_info?.expanded_symbol_payouts,
                            },
                    stops: item?.reels?.stops,
                  },
                ],
              })),
            }
          : null,
      },
    ];
  }

  if (
    sameStructuredGames.some(item => gameName?.toLowerCase()?.includes(item))
  ) {
    gameStages = [
      {
        payouts: {
          values: data?.spin?.pay_lines_to_show?.map((value) => ({
            amount: value.award,
            payline: value.index,
            count: value.indexes.length,
            indexes: value.indexes,
            direction: value.direction,
          })),
        },
        reel_window: data?.spin?.is_expended_wild?.length > 0 || data?.spin?.reels_with_crown?.length > 0
            ? data?.spin?.window?.map((item) => {
              if (item?.includes("w")) return ["w", "w", "w"];
              return item;
            })
            : data?.spin?.window,
      },
    ];

    if (data?.spin?.bonus) {
      gameStages = [
        {
          ...gameStages[0],
          bonus_game: {
            spins: [
              ...(data?.spin?.bonus?.spins
                ? data?.spin?.bonus?.spins
                : data?.spin?.bonus),
            ].map(item => ({
              stages: [
                {
                  reel_window: item?.window,
                  payouts: {
                    values: item?.pay_lines_to_show?.map(item => ({
                      ...item,
                      payline: item?.index,
                      count: item?.indexes?.length,
                    })),
                  },
                  stops: item?.stops,
                },
              ],
            })),
          },
        },
      ];
    }
  }
  console.log(data)
  return gameStages;
};

export const payLines = gameName => {
  let payLinesArray = stonesOfMagicPaylines;

  if (gameName === "stonesofmagic" || gameName === "secrettotems") {
    payLinesArray = stonesOfMagicPaylines;
  }

  if (gameName?.includes("wild-west-john")) {
    payLinesArray = wildWestJohnPaylines;
  }

  if (gameName?.includes("book-of-jones")) {
    payLinesArray = bookOfJonesPaylines;
  }

  if (gameName?.includes("smashing-hot")  || gameName === "blazing-clovers") {
    payLinesArray = smashingHotPaylines;
  }

  if (gameName === "preciousbugs" || gameName === "cyber-town") {
    payLinesArray = preciousBugsPaylines;
  }


  if (gameName?.includes("majestic-crown")) {
    payLinesArray = majesticCrownPaylines;
  }

  if (gameName === "smashing-hot-20" || gameName === "blazing-clovers-20") {
    payLinesArray = smashingHot20Paylines;
  }

  if (
    gameName?.includes("book-of-sacred") ||
    gameName?.includes("book-of-dynasty") ||
      gameName?.includes("lucky-pint") ||
      gameName?.includes("undead-riches")
  ) {
    payLinesArray = bookOfSacredPaylines;
  }

  if (gameName === "burning-20-wins" || gameName === "retro-royale") {
    payLinesArray = burningWinPaylines;
  }

  return payLinesArray;
};

export const stops = (gameName, data, isAllowed) => {
  if (!isAllowed) return [];

  let stopsArray = data?.spin?.stops || [];

  if (gameName === "stonesofmagic" || gameName === "secrettotems") {
    stopsArray = data?.spin?.spins?.[0]?.reel_stops;
  }

  if (gameName === "cyber-town" || gameName === "preciousbugs") {
    stopsArray = data?.spin?.reels?.stops;
  }

  if (gameName?.includes("book-of-jones")) {
    stopsArray = data?.spin?.reels?.stops;
  }

  return stopsArray;
};

export const paylines = (gameName, data, isAllowed) => {
  if (!isAllowed) return "-";
  let payline = data?.spin?.pay_lines_to_show?.length;

  if (gameName === "stonesofmagic" || gameName === "secrettotems") {
    payline = data?.spin?.spins?.[0]?.stages
      ?.map(item => item?.payouts)
      ?.reduce((accumulator, currentValue) => {
        if (currentValue?.values?.length) {
          return accumulator + currentValue?.values?.length;
        }
        return accumulator;
      }, 0);
  }

  if (gameName === "cyber-town" || gameName === "preciousbugs") {
    if (data?.spin?.bonus_reels?.spins_results?.length > 0) {
      payline = data?.spin?.bonus_reels?.spins_results
        ?.filter(item => item?.payouts?.spin_payouts)
        ?.reduce((accumulator, currentValue) => {
          return accumulator + currentValue?.payouts?.spin_payouts?.length;
        }, 0);
    } else {
      payline = data?.bonus_award || data?.base_award ? 1 : 0;
    }
  }

  if (gameName?.includes("book-of-jones")) {
    payline = data?.spin?.payouts?.spin_payouts?.length;
  }

  return payline;
};

export const winLines = (gameName, data, isAllowed, stage) => {
  if (!isAllowed) return [];
  let winLinesArray = data?.spin?.pay_lines_to_show || [];

  if (gameName === "stonesofmagic" || gameName === "secrettotems" || gameName === "lucky-pint") {
    if (stage?.payouts?.values) {
      winLinesArray = stage.payouts.values;
    } else {
      winLinesArray = []
    }
  }

  if (gameName?.includes("book-of-jones")) {
    winLinesArray = data?.spin?.payouts?.spin_payouts;
  }

  return winLinesArray;
};

export const bonus_spin_counter = (gameName, data, isAllowed) => {
  if (!isAllowed) return "-";
  const sameStructuredGames = [
    "smashing-hot",
    "smashing-hot-20",
    "burning-20-wins",
    "smashing-hot-94",
    "smashing-hot-96",
    "smashing-hot-20-94",
    "smashing-hot-20-96",
  ];

  let bonusSpinCount = data?.spin?.pay_lines_to_show?.length;

  if (gameName === "stonesofmagic" || gameName === "secrettotems") {
    bonusSpinCount = data?.spin?.spins?.[0]?.stages.reduce(
      (accumulator, currentValue) => {
        if (currentValue?.bonus_game) {
          return accumulator + currentValue?.bonus_game?.spins?.length;
        }
        return accumulator;
      },
      0
    );
  }

  if (gameName === "lucky-pint" || gameName === "undead-riches") {
    bonusSpinCount = data?.spin?.bonus?.spins?.length
  }

  if (gameName?.includes("book-of-jones")) {
    bonusSpinCount = data?.spin?.bonus_spins?.length;
  }

  if (gameName === "cyber-town" || gameName === 'preciousbugs') {
    bonusSpinCount = data?.spin?.bonus?.spins_results.length;
  }

  if (gameName === "ego-draconis" || gameName === "asgardparty") {
    bonusSpinCount = data?.spin?.spins?.reduce((total, spin) => {
      const stages = spin?.stages || [];
      const spinBonusCount = stages.reduce((sum, stage) => {
        const bonusGameCount = stage.bonus_game?.spins?.length || 0;
        return sum + bonusGameCount;
      }, 0);
      return total + spinBonusCount;
    }, 0);
  }

  if (
    sameStructuredGames.some(item => gameName?.toLowerCase()?.includes(item))
  ) {
    bonusSpinCount = "-";
  }
  return bonusSpinCount;
};

export const imageLinkName = (gameName, isAllowed) => {
  if (!isAllowed) return "";
  let imageLink;
  switch (gameName) {
    case "stonesofmagic":
      imageLink = "stonesofmagic";
      break;
    case "book-of-jones-96":
    case "book-of-jones-94":
      imageLink = "book_of_jones";
      break;
    case "smashing-hot":
    case "smashing-hot-20":
    case "smashing-hot-94":
    case "smashing-hot-96":
    case "smashing-hot-20-94":
    case "smashing-hot-20-96":
      imageLink = "smashing_hot";
      break;
    case "asgardparty":
      imageLink = "asgardparty";
      break;
    case "ego-draconis":
      imageLink = "ego_draconis";
      break;
    case "burning-20-wins":
      imageLink = "burning_20_wins";
      break;
    case "book-of-dynasty":
    case "book-of-dynasty-94":
    case "book-of-dynasty-96":
      imageLink = "book_of_dynasty";
      break;
    case "book-of-sacred":
    case "book-of-sacred-94":
    case "book-of-sacred-96":
      imageLink="book_of_sacred";
      break;
    case "blazing-clovers":
    case "blazing-clovers-94":
    case "blazing-clovers-96":
    case "blazing-clovers-20":
    case "blazing-clovers-20-94":
    case "blazing-clovers-20-96":
    case "blazing-clovers-40":
    case "blazing-clovers-40-94":
    case "blazing-clovers-40-96":
      imageLink = "blazing_clovers"
      break;
    case "cyber-town":
      imageLink = "cyber_town";
      break;
    case "secrettotems":
      imageLink = "secrettotems";
      break;
    case "preciousbugs":
      imageLink = "preciousbugs";
      break;
    case "wild-west-john":
    case "wild-west-john-93":
    case "wild-west-john-95":
      imageLink = "wild_west_john";
      break;
    case "majestic-crown":
    case "majestic-crown-94":
    case "majestic-crown-96":
      imageLink = "magestic_crown";
      break;
    case "lucky-pint":
      imageLink = "lucky_pint"
          break;
    case "retro-royale":
      imageLink = "retro_royale"
      break;
    case "undead-riches":
      imageLink = "undead_riches"
      break;
    default:
      imageLink = null;
      break;
  }
  return imageLink;
};
