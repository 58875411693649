import React, {useState} from 'react';
import {Breadcrumb, Card, Col, Form, Row} from "react-bootstrap";
import {Table} from "../../../components/Tables";
import CreateModal from "./components/CreateModal";
import ConfirmModal from "./components/ConfirmModal";
import {useQuery} from "react-query";
import {getGames} from "../../../services";
import {Link} from "react-router-dom";

const GamesInfo = () => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [confirmModal, setConfirmModal] = useState(false);
    const [createModal, setCreateModal] = useState(false);
    const [data, setData] = useState();
    const [selectedItem, setSelectedItem] = useState(null);

    const paginate = {
        page,
        limit,
        setPage,
        setLimit,
    };

    const {isLoading, refetch, isRefetching} = useQuery(
        [
            "games-info",
            {
                limit,
                page,
            },
        ],
        getGames,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            onSuccess: data => {
                console.log(data)
                setData(data);
            },
        }
    );

    const columns = [
        {
            className: "text-center",
            key: "all",
            name: "Name",
            render: data => (
                <div>
                <span
                    className="d-block text-truncate mx-auto"
                    style={{maxWidth: 140}}
                >
          {data.name}
        </span>
                </div>
            ),
        },
        {
            className: "text-center",
            key: "all",
            name: "Game ID",
            render: data => (
                <div onClick={() => handleItemClick(data.id)} className='d-flex align-items-baseline justify-content-center'>
                    <Link
                        to={`/games/info/${data.id}`}
                        className="text-primary text-underline cursor-pointer d-block text-truncate me-2"
                        style={{
                            textDecoration: "underline",
                            maxWidth: 100,
                        }}
                    >
                        {data.id}
                    </Link>
                </div>
            )
        },
        {
            className: "text-center",
            key: "all",
            name: "Integrators",
            render: data => (
                <div>
                <span
                    className="d-block text-truncate mx-auto"
                    style={{maxWidth: 140}}
                >
          {data.organization_id}
        </span>
                </div>
            ),
        },
        {
            className: 'text-center',
            key: 'all',
            name: 'Status',
            render: (data) => {
                return (
                    <div className="d-flex justify-content-around align-items-center">
                        <span
                            className="d-block text-truncate mx-auto"
                            style={{maxWidth: 140}}>
                            {data.is_public === true ? 'on' : 'off'}
                        </span>
                        <Form.Label className="custom-switch ps-0 cursor-pointer">
                            <Form.Control
                                type="checkbox"
                                name={`custom-switch-checkbox`}
                                className="custom-switch-input"
                                checked={data.is_active === true}
                                onClick={() => handleSwitchToggle(data.id)}
                            />
                            <span className="custom-switch-indicator custom-switch-indicator-md"></span>
                        </Form.Label>
                    </div>
                );
            },
        },
    ]

    let showModal = (modal) => {
        switch (modal) {
            case 'confirmModal':
                setConfirmModal(true);
                break;
            case 'createModal':
                setCreateModal(true);
                break;
        }
    }

    let closeModal = (modal) => {
        switch (modal) {
            case 'confirmModal':
                setConfirmModal(false);
                break;
            case 'createModal':
                setCreateModal(false);
                break;
        }
    }

    const handleSwitchToggle = (propertyValue) => {
        const selectedItem = data?.items.find(item => item.id === propertyValue);
        console.log(selectedItem)
        setSelectedItem(selectedItem);
        showModal('confirmModal');
    }

    const handleItemClick = (propertyValue) => {
        const selectedItem = data?.items.find(item => item.id === propertyValue);
        console.log(selectedItem)
        setSelectedItem(selectedItem);
        showModal('configModal');
    };

    return (
        <div className="games-page">

            {/* <!-- breadcrumb --> */}
            <div className="breadcrumb-header justify-content-between">
                <div className="left-content">
            <span className="main-content-title mg-b-0 mg-b-lg-1">
                Games info
                </span>
                </div>
                <div className="justify-content-center mg-t-55 mg-r-100">
                    <Breadcrumb className="breadcrumb">
                        <Breadcrumb.Item className="breadcrumb-item tx-15" onClick={() => showModal("createModal")}>
                            <span>Create</span>
                            <i className="p-2 border br-5 text-primary me-1 fe fe-plus align-middle mg-l-5"></i>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </div>
            </div>
            {/* <!-- /breadcrumb --> */}

            {/* <!-- row --> */}
            <Row className='overflow-hidden wagers-sets'>
                <Col xl={11}>
                    <Card className="mg-l-35">
                        <Card.Body>
                        <div className="pt-4 example1-table">
                            <Table
                                loading={isLoading || isRefetching}
                                data={data ? data : []}
                                columns={columns}
                                paginate={paginate}
                                total={data ? data?.length : 0}
                                handleClick={() => showModal('configModal')
                                }
                            />
                        </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <CreateModal onSettled={refetch} createModal={createModal} closeModal={() => closeModal('createModal')}/>
            <ConfirmModal onSettled={refetch} selectedItem={selectedItem} confirmModal={confirmModal} closeModal={() => closeModal('confirmModal')}/>
        </div>
    );
};

export default GamesInfo;