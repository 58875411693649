import React from "react";
import { enableTwoAuth, disableTwoAuth } from "../../../services";
import { useMutation, useQueryClient } from "react-query";
import { useProfile, useTwoAuth } from "../../../contexts";
import { toast, Slide } from "react-toastify";
import { isTOTPInvalid } from "../../../utils";
import { Card, Row, Col } from "react-bootstrap";

export const TwoAuthCard = () => {
  const { profileData } = useProfile();
  const client = useQueryClient();
  const { setIsShown, setReqObject, setTitle, setTOTPError } = useTwoAuth();

  const onSettled = () => {
    setIsShown(false);
  };

  const { mutate: enableTotp } = useMutation(enableTwoAuth, {
    onSuccess: () => {
      toastAlert("success", "2FA activated");
      client.invalidateQueries("profile");
      onSettled();
    },
    onError: () => {
      toastAlert("error");
      onSettled();
    },
  });

  const { mutate: disableTotp } = useMutation(disableTwoAuth, {
    onSuccess: () => {
      toastAlert("success", "2FA deactivated");
      client.invalidateQueries("profile");
      onSettled();
    },
    onError: data => {
      if (isTOTPInvalid(data)) {
        setTOTPError();
      } else {
        toastAlert("error");
        onSettled();
      }
    },
  });

  const toastAlert = (type, message) =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {message ? message : `Couldn't change`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  return (
    <Row>
      <Col xl={3} lg={6} md={12} xs={12}>
        <Card>
          <Card.Body className="py-3">
            <div className="d-flex align-items-center">
              <div className="tx-bold tx-16 me-4">Two Factor Authorization</div>
              <div className="ms-auto d-flex align-items-center">
                <div
                  className={`ms-auto main-toggle${
                    profileData?.totp_enabled ? " on" : ""
                  }`}
                  onClick={() => {
                    setTitle(
                      `${
                        profileData?.totp_enabled ? "Disable" : "Enable"
                      } Two Factor Authorization`
                    );
                    setReqObject({
                      req: profileData?.totp_enabled ? disableTotp : enableTotp,
                      variables: {},
                    });
                    setIsShown(true);
                  }}
                >
                  <span></span>
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
