import httpClient from "./httpClient";

//Users

export const getUsers = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(`/accounts?${params.toString()}`);
  if (data) {
    return data.data;
  }
};

export const createUser = async formData => {
  const { data } = await httpClient.post("/accounts", { ...formData });
  if (data) {
    return data.data;
  }
};

export const deleteUser = async id => {
  const { data } = await httpClient.delete(`/accounts/${id}`);
  if (data) {
    return data.data;
  }
};

//Roles

export const getRoles = async ({ queryKey }) => {
  const params = new URLSearchParams(queryKey[1]);
  const { data } = await httpClient.get(`/roles?${params.toString()}`);
  if (data) {
    return queryKey?.[1]?.userType && queryKey?.[1]?.userType !== "root"
      ? data.data?.filter(
          role => role?.type !== "admin" && role?.type !== "root"
        )
      : data.data;
  }
};

export const createRole = async formData => {
  const { data } = await httpClient.post("/roles", { ...formData });
  if (data) {
    return data.data;
  }
};

export const deleteRole = async id => {
  const { data } = await httpClient.delete(`/roles/${id}`);
  if (data) {
    return data.data;
  }
};
