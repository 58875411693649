import React from "react";
import moment from "moment";
import { useQuery } from "react-query";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getSession } from "../../services";
import { convertToCurrency } from "../../utils";
import {GameStateCard} from "./gameStateCard";
import {useCurrency} from "../../contexts";

const SessionDetail = () => {
  const { id } = useParams();
  const {currency} = useCurrency();

  const { data, isLoading } = useQuery(["session-detail",
        { id },
        {currency},
  ],
      getSession, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const renderData = displayData => {
    if (isLoading) {
      return <Spinner size="sm" />;
    }
    if (data) {
      console.log(data)
      return displayData;
    }
    return " - ";
  };

  return (
    <React.Fragment>
      <div className="breadcrumb-header justify-content-between">
        <div className="left-content">
          <span className="main-content-title mg-b-0 mg-b-lg-1">
            Session Detail
          </span>
        </div>
      </div>
      <Card>
        <Card.Body className="p-3">
          <h1 className="tx-22 mb-4">Session ID: {id}</h1>
          <Row className="m-2 py-3 bd-t">
            <Col xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Game</div>
              <div className="tx-18 text-info">
                {renderData(data?.game)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">User ID</div>
              <div className="tx-18 text-info text-truncate">
                {renderData(data?.external_user_id)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Session ID</div>
              <div className="tx-18 text-info text-truncate">
                {renderData(data?.session_token)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Integrator</div>
              <div className="tx-18 text-info text-truncate">
                {renderData(data?.integrator)}
              </div>
            </Col>
          </Row>
          <Row className="m-2 py-3 bd-t">
            <Col xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Currency</div>
              <div className="tx-18 text-info">
                {renderData(data?.currency)}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Start Balance</div>
              <div className="tx-18 text-info">
                {renderData(
                  convertToCurrency(data?.start_balance, data?.currency)
                )}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">End Balance</div>
              <div className="tx-18 text-info">
                {renderData(
                  convertToCurrency(data?.end_balance, data?.currency)
                )}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Wager</div>
              <div className="tx-18 text-info">
                {renderData(convertToCurrency(data?.wager, data?.currency))}
              </div>
            </Col>
          </Row>
          <Row className="m-2 py-3 bd-t">
            <Col xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Base Award</div>
              <div className="tx-18 text-info">
                {renderData(
                  convertToCurrency(data?.base_award, data?.currency)
                )}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={3} md={12} xs={12}>
              <div className="tx-16 text-muted">Bonus Award</div>
              <div className="tx-18 text-info">
                {renderData(
                  convertToCurrency(data?.bonus_award, data?.currency)
                )}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Created At</div>
              <div className="tx-18 text-info">
                {renderData(
                  moment(data?.created_at).format("DD-MM-YYYY HH:mm UTC")
                )}
              </div>
            </Col>
            <Col className="bd-s" xl={3} lg={6} md={12} xs={12}>
              <div className="tx-16 text-muted">Updated At</div>
              <div className="tx-18 text-info">
                {renderData(
                  moment(data?.updated_at).format("DD-MM-YYYY HH:mm UTC")
                )}
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
        <h2 style={{ padding: "1.25rem" }} className="pb-0 tx-20 mb-0">
          Game Rounds
        </h2>
          {data?.spins?.map((spin, index) => {
            return spin ? <GameStateCard data={spin}  /> : ""
          })}
    </React.Fragment>
  );
};

export default SessionDetail;
