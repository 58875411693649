import axios from "axios";
import moment from "moment";

export function getIdToken() {
  const idToken = localStorage.getItem("access_token");
  return idToken;
}

const baseURL = process.env.REACT_APP_API_ADDRESS;

const instance = axios.create({
  baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    ...(getIdToken() &&
    moment(window.localStorage.getItem("expired_at")).diff(moment()) > 0
      ? { "X-Auth": getIdToken() }
      : {}),
  },
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(null, error => {
  return Promise.reject(error);
});

const makeFetchRequest = async (url, fileName) => {
  await fetch(`${baseURL}${url}`, {
    method: "GET",
    headers: {
      "X-Auth": getIdToken(),
    },
  })
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.xlsx`;
      link.click();
    })
    .catch(console.error);
};

const httpClient = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
  getFetch: makeFetchRequest,
};

export default httpClient;
