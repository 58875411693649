import React, { useState } from "react";
import { useMutation } from "react-query";
import { useForm } from "react-hook-form";
import {
  Button,
  Col,
  Form,
  Row,
  Card,
  Spinner,
  Collapse,
} from "react-bootstrap";
import { updatePassword } from "../../../services";
import { toast, Slide } from "react-toastify";
import { isTOTPInvalid, isTOTPRequired } from "../../../utils";
import { useTwoAuth } from "../../../contexts";

export const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { isShown, setIsShown, setReqObject, setTOTPError } = useTwoAuth();
  const [open, setOpen] = React.useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setError,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      confirm_password: "",
      new_password: "",
      password: "",
    },
  });
  const toastAlert = type =>
    toast.success(
      <p className="text-white tx-16 mb-0 ">
        {type === "success"
          ? `Changed succesfully`
          : `Couldn't change password`}
      </p>,
      {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: false,
        transition: Slide,
        autoClose: 2000,
        theme: "colored",
        className: type === "success" ? "bg-primary" : "bg-danger",
        type,
      }
    );

  const { mutate } = useMutation(updatePassword, {
    onSuccess: () => {
      setLoading(false);
      setIsSubmitted(false);
      reset();
      toastAlert("success");
      setIsShown(false);
      setOpen(false);
    },
    onError: (data, variables) => {
      if (isTOTPRequired(data)) {
        setReqObject({ req: mutate, variables });
        setIsShown(true);
        return;
      }
      if (isTOTPInvalid(data)) {
        setTOTPError();
        return;
      }
      if (data?.response?.data?.data === "not valid password") {
        toastAlert("error");
        setError("password", {
          type: "wrong",
          message: "Old password is wrong",
        });
        setLoading(false);
        setIsShown(false);
      }
    },
  });

  React.useEffect(() => {
    !isShown && setLoading(false);
  }, [isShown]);

  const onSubmit = handleSubmit(formData => {
    setLoading(true);
    mutate(formData);
  });

  return (
    <Row>
      <Col xl={3} lg={6} md={12} xs={12}>
        <Card>
          <Card.Body>
            <div
              className="d-flex align-items-center justify-content-between cursor-pointer"
              onClick={() => setOpen(!open)}
            >
              <h5 className="mb-0 text-info tx-bold tx-16">Change Password</h5>
              <i
                className={`ms-auto text-info fe fe-${open ? "minus" : "plus"}`}
              ></i>
            </div>
            <Collapse in={open}>
              <Form
                className={`needs-validation bd-t mt-3${isSubmitted ? "" : ""}`}
                onSubmit={e => {
                  setIsSubmitted(true);
                  onSubmit(e);
                }}
              >
                <Form.Group className="form-group">
                  <Form.Label className="">Old Password</Form.Label>{" "}
                  <Form.Control
                    placeholder="Enter your old password"
                    type="password"
                    {...register("password", {
                      required: true,
                      validate: value => value?.trim() !== "",
                    })}
                    isInvalid={isSubmitted && errors?.password}
                    isValid={isSubmitted && !errors?.password}
                  />
                  {errors?.password && (
                    <div className="invalid-feedback">
                      {errors?.password?.type === "wrong"
                        ? errors?.password?.message
                        : "Required"}
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>New Password</Form.Label>{" "}
                  <Form.Control
                    placeholder="Enter your new password"
                    type="password"
                    {...register("new_password", {
                      required: true,
                      validate: value =>
                        value?.trim() !== "" && value?.trim()?.length > 7,
                    })}
                    isInvalid={isSubmitted && errors?.new_password}
                    isValid={isSubmitted && !errors?.new_password}
                  />
                  {errors?.new_password && (
                    <div className="invalid-feedback">
                      {errors?.new_password?.type === "wrong"
                        ? errors?.new_password?.message
                        : errors?.new_password?.ref?.value?.trim() === ""
                        ? "Required"
                        : "Password must contain at least 8 characters"}
                    </div>
                  )}
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label>Confirm Password</Form.Label>{" "}
                  <Form.Control
                    placeholder="Confirm your new password"
                    type="password"
                    {...register("confirm_password", {
                      required: true,
                      validate: value =>
                        value?.trim() !== "" && watch("new_password") === value,
                    })}
                    isInvalid={isSubmitted && errors?.confirm_password}
                    isValid={isSubmitted && !errors?.confirm_password}
                  />
                  {errors?.confirm_password && (
                    <div className="invalid-feedback">
                      {errors?.confirm_password?.type === "wrong"
                        ? errors?.confirm_password?.message
                        : errors?.confirm_password?.ref?.value?.trim() === ""
                        ? "Required"
                        : "Confirm password should match the new one"}
                    </div>
                  )}
                </Form.Group>
                <Button
                  disabled={loading}
                  variant=""
                  type="submit"
                  style={{ minHeight: 51 }}
                  className="btn btn-primary btn-block tx-16"
                >
                  {loading ? (
                    <Spinner
                      animation="border"
                      variant="primary"
                      className="spinner-border me-2 text-light"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    "Change password"
                  )}
                </Button>
              </Form>
            </Collapse>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
