import React from "react";
import { Modal, Button, Spinner, Form } from "react-bootstrap";
import { useMutation } from "react-query";
import { getQrCode } from "../services";
import { useProfile } from "./profileContext";
import { useForm } from "react-hook-form";
import { isIOS } from "../utils";

export const TwoAuthContext = React.createContext(null);

export const useTwoAuth = () => {
  const context = React.useContext(TwoAuthContext);

  if (context === undefined) {
    throw new Error(
      "useTwoAuth can only be used inside CurrencyContextProvider"
    );
  }

  return context;
};

export const TwoAuthContextProvider = ({ children }) => {
  const [isShown, setIsShown] = React.useState(false);
  const [title, setTitle] = React.useState("");
  const [reqObject, setReqObject] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [qrCode, setQrCode] = React.useState("");
  const { profileData } = useProfile();

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      totp: "",
    },
  });

  const { mutate } = useMutation(getQrCode, {
    onSuccess: data => {
      setQrCode(data);
    },
  });

  const setTOTPError = () => {
    setError("totp", {
      type: "wrong",
      message: "Code is invalid",
    });
    setLoading(false);
  };

  const onSubmit = handleSubmit(formData => {
    setLoading(true);
    reqObject && reqObject.req({ ...formData, ...reqObject.variables });
  });

  const onHide = () => {
    reset();
    loading && setLoading(false);
    setTitle("");
    setQrCode(false);
    setReqObject(null);
    setQrCode("");
    setIsSubmitted(false);
    setIsShown(false);
  };

  React.useEffect(() => {
    isShown && profileData && !profileData?.totp_enabled && mutate();
    !isShown && onHide();
    // eslint-disable-next-line
  }, [isShown]);

  return (
    <TwoAuthContext.Provider
      value={{
        isShown,
        setIsShown,
        reqObject,
        setReqObject,
        setTitle,
        modalLoading: loading,
        setModalLoading: setLoading,
        setTOTPError,
      }}
    >
      <Modal show={isShown} onHide={onHide} centered="true" size="md">
        <Modal.Header>
          <h6 className="modal-title">
            {title
              ? title
              : `Two Factor
            Authorization is Required`}
          </h6>
          <Button variant="" type="button" onClick={() => setIsShown(false)}>
            <span aria-hidden="true" className="text-dark">
              X
            </span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form
            className={`needs-validation${isSubmitted ? "" : ""}`}
            onSubmit={e => {
              setIsSubmitted(true);
              onSubmit(e);
            }}
          >
            {qrCode ? (
              <>
                <h4 className="text-center mb-0 text-info">Scan the QR code</h4>
                <div className="d-flex align-items-center">
                  <img
                    src={`data:image/png;base64, ${qrCode}`}
                    alt="qrCode"
                    style={{ width: 300 }}
                    className="mx-auto mt-4 mb-2"
                  />
                </div>
                <p className="text-muted text-center">
                  Scan the QR Code with{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={
                      isIOS()
                        ? "https://apps.apple.com/us/app/2fas-auth/id1217793794"
                        : "https://play.google.com/store/apps/details?id=com.twofasapp&pli=1"
                    }
                  >
                    <u>this app</u>
                  </a>{" "}
                  and enter the iterating number
                </p>
              </>
            ) : (
              ""
            )}
            <Form.Group className="form-group">
              <Form.Label>6 digit code</Form.Label>{" "}
              <Form.Control
                placeholder="Enter 6 digit code"
                type="text"
                autoComplete="off"
                {...register("totp", {
                  required: true,
                  validate: value =>
                    value?.trim() !== "" && value?.trim()?.length === 6,
                })}
                maxLength="6"
                isInvalid={isSubmitted && errors?.totp}
                isValid={isSubmitted && !errors?.totp}
              />
              {errors?.totp && (
                <div className="invalid-feedback">
                  {errors?.totp?.message ? errors?.totp?.message : "Required"}
                </div>
              )}
            </Form.Group>
            {title ? (
              <p className="text-muted tx-12 mb-2">
                *Note:
                {profileData?.totp_enabled
                  ? " After disabling 2FA delete the code iterator from software"
                  : " Delete code iterator from software if you have one"}
              </p>
            ) : (
              ""
            )}
            <Button
              disabled={loading}
              variant=""
              type="submit"
              style={{ minHeight: 51 }}
              className="btn btn-primary btn-block tx-16"
            >
              {loading ? (
                <Spinner
                  animation="border"
                  variant="primary"
                  className="spinner-border me-2 text-light"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Continue"
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {children}
    </TwoAuthContext.Provider>
  );
};
